// colors.scss
// Define color variables here

//
// Brand Colors
//
$cabot-red: #ce181e;
$cabot-orange: #e66a2f;
$cabot-thunderbird: #e03314;
$cabot-yellow: #f99d1c;
$cabot-blue: #64cade;
$cabot-dark-gray: #636363;
$cabot-medium-gray: #959595;
$cabot-light-gray: #c2c2c2;
$cabot-lightest-gray: #ebebeb;
$cabot-darkest-gray: #111111;
$white: #fff !default;
$black: #000 !default;

/// wireframe colors
/// @author Google
/// @link https://www.google.com/design/spec/style/color.html
/// NOTE: this uses Google's Material Design color scheme - "Grey"
$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;

$c-font-error: #d01500;

// borders
$base-border-color: $grey-400 !default;

// bgs
$base-background-color: $white !default;

// theme colors
$c-theme-primary: $grey-700;
$c-theme-secondary: $grey-500;
$c-theme-tertiary: $grey-300;

$base-outline-color: $grey-500 !default;
$base-fill-color: $grey-300 !default;
$base-body-color: $white;
