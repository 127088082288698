// image-block.scss

// base
.image-block {
  @include component-whitespace();
}

.image-block__title {
  text-align: left;
  font-family: $bold-font;
  font-size: 24px;
}

.image-block__description {
  color: $cabot-dark-gray;
}

// image media
.image-block__media {
  img {
    display: block;
    width: 100%;
  }
  // figcaption
  figcaption {
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 18px;
    max-width: 700px;
  }
}
