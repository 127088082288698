$page-banner-text-max: 800px;

.page-banner {
  overflow: hidden;
  position: relative;
  color: $white;

  &:before {
    // background-image: url("data:image/svg+xml,%3Csvg width='462' height='134' viewBox='0 0 462 134' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M430.73-114.6L297.58 116.01H31.28L430.72-114.6z' id='Rectangle-2' fill='%23000' transform='rotate(30 231 .7)'/%3E%3C/g%3E%3C/svg%3E");
    background: linear-gradient(-180deg, transparent 0%, #000000 100%);
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.page-banner__figure {
  margin: 0;

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    @media ($bp-small-max) {
      min-height: 400px;
    }
  }

  video {
    /* if object-fit is supported, we'll use that */
    width: 100%;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }
}

.has-height .page-banner__figure {
  width: 100%;
  height: 100%;

  picture {
    height: 100%;
  }

  img {
    height: 100%;
  }

  .video {
    height: 100%;
  }
}

.page-banner__caption {
  bottom: 80px;
  left: 0;
  position: absolute;
  right: 0;
  margin: auto;
  width: $max-width; // _variables.scss

  @media ($bp-x-large-max) {
    max-width: 100%;
  }
  @media ($bp-large-max) {
    padding: 0 100px 0 100px;
    bottom: 35px;
  }
  @media ($bp-small-max) {
    padding: 0 24px;
  }
}

.page-banner__title {
  color: $white;
  font-size: 44px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 57px;
  font-family: $bold-font;
  margin-top: 0;
  margin-bottom: 24px;
  max-width: $page-banner-text-max;

  @media ($bp-large-max) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  @media ($bp-x-small-max) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }
}

.page-banner__subtitle {
  font-family: $primary-font;
  font-size: 20px;
  line-height: 32px;
  color: $white;
  text-align: left;
  margin-bottom: 48px;
  max-width: $page-banner-text-max;

  @media ($bp-large-max) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
  }

  @media ($bp-x-small-max) {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}
