// buttons.scss
// Define button styles here
// color variables
// base
$base-btn-color: $grey-600 !default;
$base-btn-bg: lighten($base-btn-color, 50%) !default;
$base-btn-border: darken($base-btn-bg, 20%) !default;
$base-btn-color--active: darken($base-btn-color, 10%) !default;
$base-btn-bg--active: darken($base-btn-bg, 10%) !default;
$base-btn-border--active: darken($base-btn-border, 10%) !default;
// primary
$primary-btn-color: $grey-50 !default;
$primary-btn-bg: darken($primary-btn-color, 60%) !default;
$primary-btn-border: darken($primary-btn-color, 60%) !default;
$primary-btn-color--active: $primary-btn-color !default;
$primary-btn-bg--active: darken($primary-btn-bg, 10%) !default;
$primary-btn-border--active: darken($primary-btn-border, 10%) !default;
// secondary
$secondary-btn-color: $grey-800 !default;
$secondary-btn-bg: lighten($secondary-btn-color, 50%) !default;
$secondary-btn-border: darken($secondary-btn-bg, 20%) !default;
$secondary-btn-color--active: darken($secondary-btn-color, 10%) !default;
$secondary-btn-bg--active: darken($secondary-btn-bg, 10%) !default;
$secondary-btn-border--active: darken($secondary-btn-border, 10%) !default;

// base

.btn {
  display: inline-block;
  background-color: $cabot-red;
  color: $white;
  font-family: $bold-font;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  padding: 13px 32px;
  line-height: 1;
  /* Prevent button from inheriting line-height */
  vertical-align: middle;
  transition: color 0.35s, border-color 0.35s, background-color 0.35s;
  cursor: pointer;
  border-radius: 3px;

  &:active,
  &:hover {
    outline: 0;
    background-color: $cabot-thunderbird;
    color: $white;
    text-decoration: none;
  }
}
a.btn,
a.btn:hover {
  //override for old styles
  color: $white;
  text-decoration: none;
}

// modifiers
// primary

.btn--primary {
  color: $primary-btn-color;
  border: 1px solid $primary-btn-border;
  background-color: $primary-btn-bg;

  &:active,
  &:hover {
    color: $primary-btn-color--active;
    background-color: $primary-btn-bg--active;
    border-color: $primary-btn-border--active;
  }
}

// primary and disabled

.btn--primary.btn--disabled {
  opacity: 0.3;
}

// primary and ghost

.btn--primary.btn--ghost {
  color: $primary-btn-border;

  &:active,
  &:hover {
    color: $primary-btn-border--active;
  }
}

// secondary

.btn--secondary {
  color: $cabot-darkest-gray;
  // border: 1px solid $secondary-btn-border;
  background-color: $cabot-lightest-gray;

  &:active,
  &:hover {
    background-color: $white;
  }
}

a.btn--secondary {
  color: $cabot-darkest-gray;

  &:hover {
    color: $cabot-darkest-gray;
    text-decoration: none;
  }
}

// secondary and disabled

.btn--secondary.btn--disabled {
  opacity: 0.35;
}

// link and reset

.btn--link,
.btn--reset {
  border: 0;
  background-color: transparent;
  text-decoration: underline;

  &:active,
  &:hover {
    text-decoration: none;
    background-color: transparent;
  }

  &[disabled] {
    color: $grey-400;
    cursor: default;
    text-decoration: none;
  }
}

// cancel

.btn--cancel {
  background-color: transparent;

  &:active,
  &:hover {
    text-decoration: none;
    background-color: $grey-400;
  }

  &[disabled] {
    color: $grey-400;
    cursor: default;
    text-decoration: none;
  }
}
// disabled

.btn--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// ghost

.btn--ghost {
  background-color: transparent;

  &:active,
  &:hover {
    background-color: transparent;
  }
}
// btn tags

.btn--tag {
  padding: 2px 4px;
  font-weight: bold;

  .icon {
    height: 16px;
    width: 16px;
    margin-left: 4px;
    fill: $base-btn-border;
    transition: fill 0.35s;
  }

  &:hover {
    .icon {
      fill: $base-btn-color;
    }
  }
}

// btn sorts

.btn--sort {
  border: 0;
  background-color: transparent;
  overflow: hidden;
}
// btn icon group

.btn--icon-group {
  position: relative;
  padding-right: 24px;

  .btn_icon-group {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .icon {
      position: absolute;
      right: 0;
      height: 1.846em;
      max-height: 24px;
      width: 1.846em;
      max-width: 24px;
      fill: currentColor;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.35s;

      &:first-child {
        bottom: 35%;
        transform: translateY(35%);
      }

      &:last-child {
        top: 35%;
        transform: translateY(-35%);
      }
    }
  }
}
