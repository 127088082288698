.company-landing-page {
	#envelope {
		background: url(../../images/company-globe.gif) top center no-repeat #fff;
		background-attachment: fixed;
	}

	.promo-module .promotion-card {
		padding: 50px 0 40px 0;

		@include respond-max(700px){
			padding: 40px 25px;
		}

	}
}


.company-intro {
	padding-top: 75px;
	padding-bottom: 0px;
	position: relative;

	.col6 {
		padding-bottom: 40px;
	}

	@media screen and (max-width: 700px){
		padding-top: 55px;
	}

	@media screen and (max-width: 600px){
		padding-top: 35px;
	}

	.copy{

		h2 {
			font-size: 27px;
			font-family: "BreuerTextLight", helvetica, Arial, sans-serif;
			font-weight: normal;
			margin: 12px 0 22px 0; 
		}

		a{
			margin-top: 16px;
			display: table;
			border-bottom: 1px solid #dbdbdb;
			line-height: 22px;

			&:hover {
				text-decoration: none;
				border-bottom: 1px solid #bcbcbc;
			}

			@media screen and (max-width: 700px){
				margin-top: 30px;
			}
		}
	}

	a.locations-btn {
		background: rgba(255, 255, 255, .8);
		border-radius: 3px;
		border: 2px solid rgba(255, 255, 255, 1);
		bottom: 5px;
		display: inline-block;
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		font-size: 17px;
		line-height: 1;
		padding: 13px 15px 14px 43px;
		position: absolute;
		right: 0px;
		text-transform: uppercase;
		letter-spacing: .5px;

		@media screen and (max-width: 500px){
			position: static;
			width: 100%;
			display: block;
			clear: both;
			text-align: center;
		}

		&:hover {
			text-decoration: none;
			background: rgba(255, 255, 255, 1);

		}

		img {
			position: absolute;
				left: 6px;
				top: 6px;
			width: 32px;
		}
	}
}


.promo-module.foundation {
	padding-top: 58px;
	min-height: 270px;
	height: auto;

	@media screen and (max-width: 600px){
		padding: 40px 20px;
	}

	p {
		color: #000;
		font-size: 21px;
		line-height: 1.6em;
		margin-bottom: 29px;

		@media screen and (max-width: 600px){
			font-size: 16px;
		}
	}

	a {
		color: #000;
		background: rgba(255, 255, 255, .8);
		border-radius: 3px;
		border: 2px solid rgba(255, 255, 255, 1);
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		padding: 7px 16px 9px 9px;
		text-transform: uppercase;
		letter-spacing: .5px;

		&:hover {
			color: #000;
			text-decoration: none;
			background: rgba(255, 255, 255, 1);

		}

		img {
			width: 20px;
			margin-right: 6px;
			position: relative;
			top: 4px;
		}
	}
}


// Newsroom styles

.news-items.data-table {
	th {
		color: #fff;
		text-transform: none;
	}

	tr {
		background: none;

		&:nth-child(even) {
			background-color: #F5F5F5;
		}
	}
}