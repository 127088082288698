.callout {
  @include component-whitespace();
  font-family: $primary-font;
  background-color: $white;
  background-image: url("../img/callout-background.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 165px 185px;
  border: solid 1px $grey-300;
  color: $cabot-darkest-gray;
  line-height: 1.2;
  overflow: hidden;
  padding: 71px 80px;
  position: relative;

  &__container {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media screen and ($bp-large-max) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__col {
    // max-width: 320px;

    @media screen and ($bp-large-max) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__title {
    font-size: 27px;
    font-family: $bold-font;
    font-weight: 500;
    line-height: 32px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    text-transform: uppercase;
    padding-right: 20px;
    min-width: 240px;

    @media screen and ($bp-large-max) {
      margin-bottom: 12px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding-right: 40px;

    @media screen and ($bp-large-max) {
      max-width: initial;
      margin-bottom: 12px;
    }
  }

  .callout__cta {
    flex-grow: 2;
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
    justify-content: flex-end;

    //super opinionated legacy link styles
    a:hover {
      text-decoration: none;
    }

    @media screen and ($bp-large-max) {
      justify-content: flex-start;
    }
  }
}
