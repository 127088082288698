﻿.form {
	max-width: 465px;

	fieldset {
		border: none;
		padding: 0;
	}

	.form-row,
	.button-row {
		clear: both;
		//padding: 0 0 16px 0;

		&::after{
			display:table;
			content: "";
			clear:both;
		}

	}

	.form-col {
		width: 50%;
		float: left; 

		@media screen and (max-width:$breakpoint-small) {
			width: 100%;
			padding: 0 0 16px 0 !important;
			float: none; 

		}
		
		&:first-child {
			padding-right: 18px;
		}

		&:last-child {
			padding-left: 18px;
		}

	}
	
	input, 
	textarea {
		border: 1px solid #d0d2d1;
		background: #f7f7f7;
		width: 100%;
		border-radius: 3px;
		font-size: 16px;
		padding: 8px;
	}

	label {
		display: block;
		text-transform: uppercase; 
		color: #a2a5a4;
		font-size: 13px;
		font-family: 'BreuerTextRegular', helvetica, arial;
		margin-bottom: 7px;
	}

	.button-row {
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid rgba(0, 0, 0, .1);

		button {
			border: none;
			border-radius: 0;
			background-color: #64cade;
			color: #ffffff;
			padding: 11px 27px;
			font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		}
	}

}

#sales-form {
	label.error {
		color: #e23e21;
	}

	input.error {
		border: 1px solid #e23e21;

		&:focus {
			border: 1px solid #e23e21 !important;
		}
	}

	textarea.error {
		border: 1px solid #e23e21;
	}

	input.button.submit {
		border: none;
		border-radius: 0;
		background-color: #64cade;
		color: #ffffff;
		padding: 11px 27px;
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		width: auto;
	}

	#form-success-msg {
		display: block;
		line-height: 1.4;
		margin-left: 124px;
	}
	input[disabled],
	textarea[disabled] {
		color: #a6a6a6 !important;
	}
	input.form-submit[disabled] {
		background: #a6a6a6 !important;
		color: #fff !important;
		float: left;
		font-style: italic !important;
		margin-bottom: 30px;
	}


}

.nl-nl #customer-service.tab dl dt {
	width: 130px;
}
