// typography.scss
// Define typography styles here

// typographic variables

//primary and secondary from legacy code; adding bold to account for medium font
$primary-font: "BreuerTextLight", helvetica, Arial, sans-serif;
$secondary-font: Georgia, "Times New Roman", serif;
$bold-font: "BreuerTextMedium", helvetica, Arial, sans-serif;

/// '$helvetica' variable overrides bourbon's font-stack variable of the same name
/// @author thoughtbot
/// @link https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_font-stacks.scss
$opensans: "Open Sans", "Helvetica Neue", helvetica, arial, sans-serif;
$bitter: "Bitter", georgia, "Times New Roman", times, serif;
$montserrat: "Montserrat", "Lucida Grande", helvetica, arial, sans-serif;

/// Font Stack Map to define wireframe font-stack
/// @author Hmphry
/// @link http://hmphry.com/useful-sass-mixins
///
/// @example scss - usage
///     h1 {
///         @include font(helvetica);
///     }
///
///     p {
///         @include font(helvetica, bold);
///     }
/// @example css - CSS output
///    h1 {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 400;
///        font-style: normal;
///    }
///
///    p {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 600;
///        font-style: normal;
///    }
///
/// TODO: Update with your font-stack scheme and call the font() function too!
/// NOTE: Needs a trailing semi-colon on the last declaration!
$font-stack: (
    group: "opensans",
    id: light,
    font: (
      $opensans
    ),
    weight: 300,
    style: normal
  ),
  (
    group: "opensans",
    id: regular,
    font: (
      $opensans
    ),
    weight: 400,
    style: normal
  ),
  (
    group: "opensans",
    id: regular,
    font: (
      $opensans
    ),
    weight: 500,
    style: normal
  ),
  (
    group: "opensans",
    id: semibold,
    font: (
      $opensans
    ),
    weight: 600,
    style: normal
  ),
  (
    group: "opensans",
    id: bold,
    font: (
      $opensans
    ),
    weight: 700,
    style: normal
  ),
  (
    group: "bitter",
    id: regular,
    font: (
      $bitter
    ),
    weight: 400,
    style: normal
  ),
  (
    group: "bitter",
    id: regular-italic,
    font: (
      $bitter
    ),
    weight: 400,
    style: italic
  ),
  (
    group: "bitter",
    id: bold,
    font: (
      $bitter
    ),
    weight: 700,
    style: normal
  ),
  (
    group: "montserrat",
    id: regular,
    font: (
      $montserrat
    ),
    weight: 400,
    style: normal
  ),
  (
    group: "montserrat",
    id: bold,
    font: (
      $montserrat
    ),
    weight: 700,
    style: normal
  );

@mixin font($group, $id: regular) {
  @each $font in $font-stack {
    @if ($group == map-get($font, group) and $id == map-get($font, id)) {
      font-family: map-get($font, font);
      font-weight: map-get($font, weight);
      font-style: map-get($font, style);
    }
  }
}

// color variables
$base-body-color: $grey-700 !default;

// base styles

// body {
//   // @include font(opensans);
//   font-family: $primary-font;
//   // font-size: 16px;
//   // line-height: 1.25;
//   color: $base-body-color;
//   -webkit-font-smoothing: antialiased;
// }

// headings

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .txt-h1,
// .txt-h2,
// .txt-h3,
// .txt-h4,
// .txt-h5,
// .txt-h6 {
//   @include zero;
//   font-family: $bold-font;
//   font-weight: 500;
//   // @include font(bitter, bold);
//   line-height: 1.3;
// }

// h1,
// h2,
// h3 {
//   margin-bottom: 12px;
// }

// h4,
// h5,
// h6 {
//   margin-bottom: 9px;
// }

// h1,
// .txt-h1 {
//   font-size: 3.5rem;
//   line-height: 1.2;

//   @media ($bp-large-max) {
//     font-size: 2.2rem;
//   }

//   @media ($bp-x-small-max) {
//     font-size: 1.8rem;
//     line-height: 1.1;
//   }
// }

// h2,
// .txt-h2 {
//   font-size: 2rem;
//   line-height: 2.625rem;
// }

// h3,
// .txt-h3 {
//   font-size: 1.563rem;
// }

// h4,
// .txt-h4 {
//   font-size: 1.375rem;
// }

// h5,
// .txt-h5 {
//   font-size: 1.5rem;
// }

// h6,
// .txt-h6 {
//   font-size: 1.438rem;
//   line-height: 1;
//   text-transform: uppercase;

//   @include font(montserrat);
// }

// // paragraphs

// p {
//   @include zero;
//   margin-bottom: 18px;
//   font-size: 18px;
//   line-height: 1.6;
// }

// small text

.txt-small {
  font-size: 14px;
}

.underlined-header {
  font-size: 33px;
  letter-spacing: 0.34px;
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  display: block;
  font-family: $bold-font;
  font-weight: 500;

  &:after {
    content: "";
    background-color: $cabot-red;
    display: block;
    height: 4px;
    margin-top: 16px;
    margin-bottom: 0;
    width: 32px;
  }
}
