/* import any content partials here */
@import "content/homepage";
@import "content/responsibility";
@import "content/solutions";
@import "content/advancing";
@import "content/search";
@import "content/company";
@import "content/events";
@import "content/locations";
@import "content/regions";
@import "content/news-room";

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

::-moz-selection {
    background: rgba(230, 230, 230, 0.7);
    color: #000;
}

::selection {
    background: rgba(230, 230, 230, 0.7);
    color: #000;
}

a,
a:link,
a:active {
    color: #e03314;
    outline: 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: #db371a;
    }
}

a:focus {
    color: #db371a;
    outline: 0;
    text-decoration: underline;
}

h1 {
    clear: both;
    font-family: "BreuerTextRegular", helvetica, arial;
    letter-spacing: -0.015em;
    font-size: 39px;
    font-weight: normal;
    margin-bottom: 25px;
    margin-top: 30px;
    text-transform: uppercase;
    line-height: 1.2em;
    /*@media screen and (max-width:$breakpoint-first) {
		cursor: pointer;
		padding-bottom: 15px;

		&.page-title:before {
			content: "";
			display: inline-block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 11px 8px 0 8px;
			border-color: $primary-text-color transparent transparent transparent;
			margin: 15px 0 9px 15px;
			float: right;
			transition: ease all 250ms;
			position: relative;
			top: 0;
		}

		&.open:before {
			transform: rotate(180deg);
			transition: ease all 250ms;
			position: relative;
			top: -3px;
			
		}
	}*/
    @media screen and (max-width: $breakpoint-header) {
        font-size: 27px;
        margin-top: 3px;
        line-height: 1.3em;

        &:before {
            margin-top: 16px;
        }
    }
}

.wrapper {
    clear: both;
}

.landing-page h1 {
    cursor: text;

    &:before {
        content: "";
        border: none !important;
        height: 0;
        width: 0;
    }
}

h2 {
    // clear:both;
    margin-top: 12px;
}

h3 {
    // clear:both;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}

hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
    margin-top: 37px;
}

p {
    line-height: 1.7;
    letter-spacing: 0.03em;
}

.visible {
    opacity: 1 !important;
    visibility: visible;
}

.main-content ul {
    list-style: none;
    padding-left: 5px;

    li {
        background: url("../../images/icon-bullet.png") no-repeat 0px 8px;
        background-size: 9px 9px;
        letter-spacing: 0.3px;
        line-height: 1.7;
        margin-bottom: 15px;
        padding-left: 20px;
    }
}

.bullets-diamond li {
    background: url("../../images/icon-bullet.png") no-repeat 0px 8px;
    background-size: 9px 9px;
    letter-spacing: 0.3px;
    line-height: 1.7;
    margin-bottom: 15px;
    padding-left: 20px;
    list-style-type: none;
}

/* ===================================================================== HEADERS */
.page-header {
    font-size: 22px;
}

.section-header {
    font-size: 18px;
}
// Special class assigned to h1.page-title if the page is an 'orphan' page and requires
// a 'back to parent' breadcrumb link
.child-page {
    margin-top: 7px;
}

.header-tile {
    background: url("../images/pattern_tile3.png") top left repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
}
/* ===================================================================== BUTTONS */
.button {
    @include border-radius($default-border-radius);
    background-color: $primary-button-color;
    color: $tertiary-text-color;
    padding: 5px 20px;

    &:hover {
        text-decoration: none;
    }

    &:active {
    }
}
/* container for buttons */
.buttons {
    float: left;
    margin: 15px 0;
    width: 100%;

    .button {
        float: left;
        margin-right: 25px;
    }
}

.main-content {
    @extend .col9;
    padding-bottom: 173px;
    padding-top: 64px;

    @media screen and (max-width: 768px) {
        padding-top: 0;
        width: 100% !important;
    }

    @media screen and (max-width: $breakpoint-medium) {
        padding-bottom: 90px;
    }

    &.one-col {
        width: 100%;
    }
}

.page-title {
    @include respond-max($breakpoint-header) {
        margin-bottom: 13px;
        margin-top: 25px;
    }
}

p.subhead {
    font-family: "BreuerTextLight", helvetica, arial;
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
    color: #a2a5a4;
    margin-top: 0;
    margin-bottom: 33px;
    letter-spacing: 0.015em;

    @media screen and (max-width: $breakpoint-first) {
        //padding-top: 40px;
    }

    @media screen and (max-width: $breakpoint-header) {
        font-size: 22px;
        line-height: 30px;
    }

    @media screen and (max-width: $breakpoint-small) {
    }
}

p.page-image {
    float: right;
    padding: 0 0 10px 5%;
    margin: 0;
    width: 50%;

    @media screen and (max-width: $breakpoint-small) {
        padding: 0;
        float: none;
        width: 100%;
    }
}
// table styles ------------------------------------------------------------------------------//
.main-content table,
.main-content .table-container {
    font-size: 14px;
    width: 100%;
}

.main-content table th,
.main-content table td,
.main-content .table-container .row > span {
    padding: 8px 0 8px 15px;
    vertical-align: top;
    text-align: left;

    &.data-sheet-download {
        text-align: center;
        padding-right: 15px;
    }

    &.msds-download {
        text-align: center;
        padding-right: 15px;
    }
}

.main-content table tr,
.main-content .table-container .row {
    background: url(../images/product-border.png) left bottom repeat-x;
    background: url(../images/product-border.png) left bottom 1px repeat-x;
}

.table-container .mobile-subnav {
    display: none !important;
}

.card[data-overlay-url] {
    cursor: pointer;
}
// @Cabot Labs styling
// .nav-menu,
// .main-side,
// .cabot-labs {
// 	span span {
// 		font-family: verdana, helvetica;
// 	}
// }
.cookie-policy-summary {
    background: #f2f3f4;
    border-bottom: 1px solid #e5e7e6;
    height: 54px;
    padding-top: 0;
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    z-index: 99999;

    @include respond-max(620px) {
        height: 120px;
        padding-top: 10px;

        p {
            padding: 0 70px 0 25px;
        }

        .cookie-close {
            background-color: #f2f3f4 !important;
            top: 0;
        }
    }

    @include respond-max(400px) {
        height: 165px;
    }

    p {
        font-family: "BreuerTextRegularItalic", helvetica, arial;
        font-size: 14px;
        padding-right: 54px;
        position: absolute;
        left: 0;
        right: 0;

        @include respond-max(1076px) {
            margin-top: 5px;
        }

        @include respond-max(620px) {
            font-size: 14px;
        }

        @include respond-max(523px) {
            //line-height: 1.3;
        }

        @include respond-max(366px) {
            //line-height: 1;
        }
    }

    .cookie-close {
        background-color: #e5e7e6;
        background-image: url("../../images/eu_policy_close@2x.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 26px 26px;
        display: block;
        height: 54px;
        width: 54px;
        position: absolute;
        right: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .cookie-policy-buttons {
        position: absolute;
        right: 10px;
        top: 15px;

        .accept-button span {
            background: #e03314;
            border-radius: 0;
            color: #fff;
            margin-right: 10px;
            padding: 10px 20px;

            &:hover {
                cursor: pointer;
            }
        }

        .reject-button span {
            background: #767676;
            border-radius: 0;
            color: #fff;
            padding: 10px 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.wrapper .cookie-policy-summary {
    background: #fff;
    border: none;
    position: static;
    bottom: auto;

    p {
        padding-right: 0;
        position: static;
        text-align: left;
    }

    .cookie-policy-buttons {
        float: left;
        margin-top: 15px;
        position: static;
    }
}
