.responsibility-container {
  background: url(../../images/back_default.jpg) center;
  background-size: cover;
  padding-bottom: 250px;
  padding-top: 39px;
  position: relative;

  @media screen and (max-width: $breakpoint-medium) {
    padding-bottom: 150px;
    padding-top: 20px;
  }

  * {
    transition: all 600ms;
  }

  .wrapper {
    position: relative;
    z-index: 2;

    h1 {
      margin-bottom: 47px;
      color: #ffffff;

      @media screen and (max-width: $breakpoint-header) {
        font-size: 30px;
        margin-top: 15px;
      }

      a.download-report {
        float: right;
        background: rgba(0, 0, 0, 0.2);
        border: 2px solid rgba(255, 255, 255, 0.4);
        color: white;
        padding: 13px 15px 14px 43px;
        font-size: 17px;
        border-radius: 4px;
        letter-spacing: 0;
        line-height: 1;
        margin-top: 6px;

        &:hover {
          background: rgba(0, 0, 0, 0.4) !important;
          border: 2px solid rgba(255, 255, 255, 0.5);
        }

        img {
          position: absolute;
          left: 6px;
          top: 6px;
          width: 32px;
        }

        @media screen and (max-width: $breakpoint-medium) {
          display: none;
        }
      }
    }

    a {
      background: #ffc241;
      color: $primary-text-color;
      display: table;
      font-size: 21px;
      margin-bottom: 31px;
      padding: 7px 13px;
      position: relative;

      span {
        text-transform: uppercase;
        font-family: "BreuerTextRegular";
      }

      @media screen and (max-width: $breakpoint-medium) {
        display: block;
        font-size: 21px;
        margin-bottom: 18px;
        width: 100%;
      }

      @media screen and (max-width: $breakpoint-small) {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
        width: 100%;
      }

      &:hover {
        color: $primary-text-color;
        background: #ffffff !important;
        text-decoration: none;

        span {
          color: #e03314;
        }
      }

      &:nth-child(3) {
        background: #ffa822;
      }

      &:nth-child(4) {
        background: #ff770a;
      }

      &:nth-child(5) {
        background: #f54b12;
      }

      &:nth-child(6) {
        background: #e50017;
      }
    }
  }

  .responsibility-background {
    background-size: cover;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-position: center;
  }

  .responsibility-pattern {
    background: url("../images/pattern_tile2.png") center bottom;
    opacity: 1;
  }
}

// Responsibility - Environment

.landing-header {
  background-size: 100% !important;
  background-attachment: fixed;
  height: 300px;
  margin: 0;
  position: relative;
  background: rgba(252, 175, 23, 1);
  width: 100%;

  @include respond-max(768px) {
    display: none;
  }

  @media screen and (min-width: 1600px) {
    background-size: cover !important;
    background-position: center !important;
    background-attachment: scroll !important;
  }

  &.industry {
    @include respond-max(540px) {
      display: block;
    }
  }

  a.download-report {
    float: right;
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.4);
    color: white !important;
    padding: 13px 15px 14px 43px;
    font-size: 17px;
    border-radius: 4px;
    line-height: 1;
    position: relative;
    margin-top: 75px;
    text-transform: uppercase;
    font-family: "BreuerTextRegular";
    -webkit-transition: all ease 300ms;
    transition: all ease 300ms;

    &:hover {
      background: rgba(0, 0, 0, 0.4) !important;
      border: 2px solid rgba(255, 255, 255, 0.5);
      text-decoration: none;
      -webkit-transition: all ease 300ms;
      transition: all ease 300ms;
    }

    img {
      position: absolute;
      left: 6px;
      top: 6px;
      width: 32px;
    }

    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }

  &.page-editor {
    background-attachment: local !important;
  }

  @media screen and (max-width: 1280px) {
    background-size: 1285px !important;
  }

  @media screen and (max-width: $breakpoint-large) {
    height: 260px;
    background-size: cover !important;
    background-attachment: scroll !important;
  }

  @media screen and (max-width: $breakpoint-header) {
    height: 200px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    height: 200px;
  }

  .responsibility-pattern {
    background: url("../images/pattern_tile2.png") center bottom;
    height: 300px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .wrapper {
    position: relative;
    height: 300px;
    overflow: visible;

    @media screen and (max-width: $breakpoint-header) {
      height: 200px;
    }
  }

  h1 {
    background: #dfa838;
    background: rgba(252, 175, 23, 1);
    color: #ffffff;
    display: inline-block;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
    padding: 8px 13px;
    position: absolute;
    bottom: 0;
    left: 25px;
    -webkit-transition: left ease 300ms;
    transition: left ease 300ms;

    &:hover {
      cursor: pointer;
    }

    &.nav-open img {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform ease 300ms;
      transition: transform ease 300ms;
    }

    img {
      margin-left: 30px;
      position: relative;
      top: -3px;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: -webkit-transform ease 300ms;
      transition: transform ease 300ms;
    }
  }

  h2 {
    margin: 0;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-size: 40px;
    font-weight: 300;
    padding-top: 65px;
    text-transform: uppercase;
  }
}

/* Other ------------------------------------------------------------------------- */

.animated {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: ease all 1000ms;
  transition: ease all 1000ms;
  position: relative;

  @media screen and (max-width: $breakpoint-small) {
    opacity: 1;
    visibility: visible;
  }
}

/* IE9/10 hack */
@media screen and (min-width: 0\0) {
  .animated {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.fade-in {
  -webkit-animation: fadeIn 1000ms; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  animation: fadeIn 1000ms; /* Safari and Chrome */
  animation-fill-mode: forwards;
  visibility: visible;

  @media screen and (max-width: $breakpoint-small) {
    animation: none;
    -webkit-animation: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1, 1);
  }
}

.msds-product-index-container {
  float: left;
  width: 100%;
}

.content-carousel.split-view-module.one-col .flex-direction-nav {
  position: relative;
  top: 188px;
}

.content-carousel.one-col h3 {
  font-size: 33px;
}

.content-carousel {
  background: none;
  margin-bottom: 0;
  padding-bottom: 0px;
  padding-top: 16px;
  position: relative;

  &.one-col {
    .wrapper {
      padding: 0 25px;
    }
  }

  h3 {
    color: $primary-text-color;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-size: 25px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;

    img {
      position: relative;
      top: 2px;
    }
  }

  .grid-layout {
    &.card {
      @include respond-max(700px) {
        margin-left: 0 !important;
      }
    }

    // !!pcb this is a hack to override the default grid column width because we
    // want to use the same markup for both one column and multicolumn layouts.
    &.card .col6 {
      margin-top: 30px;
      margin-left: 50px;
      width: 100%;

      &.image {
        margin-left: 5%;
        width: 60.666%;

        @include respond-max(700px) {
          margin-left: 0;
          padding: 0;
        }
      }

      &.has-image {
        width: 32%;
        margin-left: 0;

        @include respond-max(700px) {
          padding: 0;
        }
      }

      img {
        margin: 0 auto;
      }
    }

    .prev,
    .next {
      width: 1 / 12 * 100%;
      float: left;
      height: 100px;
      padding-left: $gutter-width / 2;
      padding-right: $gutter-width / 2;
      padding-top: 30px;

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }

    .next {
      text-align: right;
    }

    .chart,
    .description {
      float: left;
      padding-left: $gutter-width / 2;
      padding-right: $gutter-width / 2;
    }

    &.wrapper {
      padding: 0;
    }
  }

  &.graph-module {
    font-size: 14px;

    .flex-direction-nav {
      position: absolute;
      top: 180px;
      width: 100%;

      .flex-prev {
        left: 0;
      }

      .flex-next {
        right: 0;
      }
    }
  }

  &.story-module {
    .flex-direction-nav {
      position: absolute;
      top: 500px;
      width: 100%;
    }
  }

  .chart {
    text-align: center;
    padding-left: 80px !important;
    width: 60%;

    @media screen and (max-width: $breakpoint-medium) {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .description {
    padding-top: 0;
    padding-right: 40px !important;
    width: 40%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    h4 {
      color: #e23e21;
      font-size: 17px;
      font-family: "BreuerTextRegular", helvetica, arial;
    }

    p {
      color: $primary-text-color;
      font-size: 14px;
      margin-top: 8px;
      line-height: 1.7em;
    }
  }

  h4 {
    font-family: "BreuerTextRegular", helvetica, arial;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
  }

  &.stacked {
    .wrapper {
      padding: 0 20px;

      .full {
        margin-left: 0;
      }
    }

    .card {
      background: url("../images/dottedline.png") bottom repeat-x;
      margin-bottom: 40px;
      padding-bottom: 50px;

      p:last-child {
        margin-bottom: 0;
      }
    }
    .grid-layout.card {
      .col6.image {
        margin-left: 0;
        margin-right: 5.5%;
        padding: 0;
        width: 57.2%;
      }

      .col6.has-image {
        margin-left: 0;
        padding: 0;
        width: 37%;
      }

      &:last-of-type {
        background-image: none;
      }
    }
  }
}

.module-content {
  li {
    text-align: left;
  }
}

.story-module {
  padding-top: 17px;

  h3 {
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    color: #222222;
    font-size: 25px;
  }
  .grid-layout {
    padding-top: 4px;
  }

  .prev,
  .next {
    padding-top: 100px;

    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }

  .next {
    text-align: right;
  }

  .image.col9 {
    padding: 0;
  }

  .module-content {
    padding: 30px 11.11111% 0 11.11111%;
    text-align: center;

    .full {
      display: none;
    }

    h4 {
      color: #54bcd1;
      font-family: "BreuerTextLight", helvetica, arial;
      font-size: 19px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    p {
      margin-top: 13px;
      margin-bottom: 13px;
    }

    li {
      text-align: left;
    }
  }

  &.stacked {
    .module-content {
      margin-bottom: 60px;
      padding: 20px 11.11111% 0 11.11111%;
    }
  }

  &.flexslider-one-col {
    padding-top: 0;
  }
}

.card {
  .full {
    display: none;
    clear: both;
    margin-left: 68px;
    padding: 20px 0;
  }

  &.graph {
    .full {
      padding: 0 40px 0 80px;
    }
  }
}

.card,
.story-module {
  .more-toggle,
  .less-toggle {
    cursor: pointer;
  }
}

.card {
  .more-toggle {
    font-size: 14px;
  }
}

.one-col .card .more-toggle {
  font-size: 18px;
}

.bottom-focus-areas {
  margin-top: 50px;

  .focus-area {
    background: #f5f5f5;
    display: block;
    font-size: 14px;
    line-height: 1.3;
    padding: 30px;
    position: relative;
    text-align: center;
    min-height: 265px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-bottom: 20px;
      min-height: 0;
    }

    &:before {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 100px 60px 0 0;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      left: 0;
      top: 0;

      @media screen and (max-width: $breakpoint-medium) {
        border-width: 50px 30px 0 0;
      }
    }

    h3 {
      color: #a2a5a4;
      font-family: "BreuerTextLight", helvetica, arial;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    > img {
      margin-bottom: 19px;
    }

    hr {
      margin-bottom: 8px;
      margin-top: 9px;
    }
  }
}

// MSDS search bar tweak
.msds-product-index-container .product-search-bar.fixed {
  top: 0;
}

/* Other ------------------------------------------------------------------------- */

.animated {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: ease all 1000ms;
  transition: ease all 1000ms;
  position: relative;

  @media screen and (max-width: $breakpoint-small) {
    opacity: 1;
    visibility: visible;
  }
}

.fade-in {
  -webkit-animation: fadeIn 1000ms; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  animation: fadeIn 1000ms; /* Safari and Chrome */
  animation-fill-mode: forwards;
  visibility: visible !important;
  opacity: 1 !important;

  @media screen and (max-width: $breakpoint-small) {
    animation: none;
    -webkit-animation: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
