﻿html {
  &.modal-layout {
    background: #FFF;
  }
}

.modal-body {
  padding: 15px;

  h2 {
    margin: 0;
  }

  h3 {
    font-size: 14px;
    text-align: left;
    margin: 0;

    &.section-head {
      margin: 10px 0;
    }
  }

  .focus {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    clear: both;
    padding: 15px;
    margin: 30px 0;
    min-height: 75px;

    @include respond-max(520px) {
      min-height: 105px;
      padding: 15px 0;
    }

    .col {
      float: left;

      &.data {
        width: 40%;
      }

      &.link {
        padding-top: 5px;
        text-align: center;
        width: 20%;
      }

      p {
        color: $secondary-text-color;
        font-size: 12px;
        font-weight: 800;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }

  .data-table {
    .link {
      text-align: center;

      img {
        width: 24px;
      }
    }
  }
}

.modal-body .data-table .link img {
  width: 24px;
}


.modal-body .col.link img {
  width: 32px;
}
