.event-card {
	.col6 {
		//width: 47.5%;
		margin-bottom: 10px;

		h4 {
			font-size: 20px;
			margin-bottom: 8px;
			margin-top: 0;
			text-transform: uppercase;
		}
	}

	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}

	h3 {
		font-size: 26px;
		font-weight: bold;
		margin: 40px 0;
	}

	p {
		margin: 8px 0;
	}
}

.event-module {
	hr {
		background: url('../images/product-border.png');
		border-top: none;
		height: 1px;
		margin: 40px auto;
	}
}