.featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 40px 100px;
  text-align: center;
  background-repeat: no-repeat;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  &--left {
    text-align: left;
    align-items: flex-start;
  }

  &--right {
    text-align: right;
    align-items: flex-end;
  }

  @media ($bp-medium-max) {
    padding: 16px 48px;
  }
  @media ($bp-small-max) {
    padding: 16px;
  }
}
.featured-text__headline {
  font-family: $bold-font;
  font-size: 44px;
  margin-bottom: 24px;
  color: $black;
  max-width: 100%;

  @media ($bp-large-max) {
    font-size: 34px;
  }
  @media ($bp-small-max) {
    font-size: 24px;
  }
}
.featured-text__description {
  font-size: 22px;
  line-height: 32px;
  font-family: BreuerTextRegular;
  margin-bottom: 38px;
  max-width: 100%;

  @media ($bp-large-max) {
    font-size: 18px;
    line-height: 26px;
  }
  @media ($bp-small-max) {
    font-size: 16px;
    line-height: 24px;
  }
}
