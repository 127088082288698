
// Advancing landing page ---------------------------------------------------------------- //

.advancing-landing-video {
	width: 100%;
	overflow: hidden;
	line-height: 0;
	position: relative;
	height: 600px;
	background: rgba(0, 0, 0, .9);

	&.video-playing{
		height: auto;
	}

	.down-dots {
		width: 2px;
		background: rgba(255, 255, 255, .5);
		height: 39px;
		position: absolute;
		left: 50%;
		bottom: 0;
		margin-left: -1px;
		transform: translate3d(0, 0, 0);

		img {
			max-width: 200px;
			position: absolute;
			left: -4px;
			top: -8px;
		}
	}

	video {
		width: 100%;
		transform-style: preserve-3d;
		transform: translate3d(0, 0, 0);

		&.full {
			 cursor:url(../../images/video_close.png), auto;
		}

		&.teaser{
			@media screen and (max-width: 1280px) {
				height: 600px;
				width: auto;
				position: relative;
				left: 50%;
				margin-left: -640px;
			}
		}
	}
	
	.line-pattern {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url(../../images/pattern_tile4.png) top left repeat;
	}
}

.content {
	color: #ffffff;
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 500px;
	margin: -150px 0 0 -250px;
	line-height: 1.3em;
	font-size: 22px;

	@media screen and (max-width: 500px) {
		width: 300px;
		margin: -215px 0 0 -150px;
	}



	h1 {
		font-size: 44px;
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		width: 300px ;
		margin: 0 auto 25px auto;
		line-height: 1.1em;
		padding: 0;
	}

	.play {
		display: block;
		width: 70px;
		height: 70px;
		border-radius: 35px;
		border: 2px  solid rgba(255, 255, 255, .3);
		margin: 20px auto 0 auto;
		background: rgba(0,0, 0, .3);
		padding-top: 21px;
		opacity: .7;
		transition: all linear 100ms;
		
		&:hover {
			border: 2px  solid rgba(255, 255, 255, .5);
			background: rgba(0,0, 0, .5);
			opacity: 1;
			transition: all linear 100ms;
		}

		img {
			margin-left: 5px;
		}
	}
}

.landing-header {
	z-index: 4;
}


.advancing-landing-link {
	display: block;
	width: 100%;
	background: center;
	background-attachment: fixed;
	height: 300px;
	background-size: cover;
	font-size: 38px;
	color: #ffffff !important;
	text-decoration: none;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	padding-top: 95px;
	position: relative;
	opacity: 1;

	@media screen and (max-width: $breakpoint-medium) {
		font-size: 30px;
		height: 250px;
		padding-top: 80px;
	}
	
	&:before {
		content: "";
		width: 4px;
		background: url(../../images/pattern_dots.png) top center repeat-y;
		background-attachment: fixed;
		height: 85px;
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 10;
		margin-left: -2px;

		@media screen and (max-width: $breakpoint-medium) {
			height:65px;
		}
	}

	&:after {
		content: "";
		width: 4px;
		background: url(../../images/pattern_dots.png) top center repeat-y;
		background-attachment: fixed;
		height: 85px;
		position: absolute;
		left: 50%;
		bottom: 0;
		margin-left: -2px;

		@media screen and (max-width: $breakpoint-medium) {
			height:55px;
		}
	}

	&.highlighted {

		.background{
			opacity: .75;
		}

		span:after{
			background: rgba(255, 255, 255, .9);
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-attachment: fixed;
		opacity: .5;

		@include respond-max(640px) {
			background-image: none !important;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url(../../images/pattern_tile4.png) top left repeat;
		}
	}
	&:hover {
		text-decoration: none !important;
	}
	
	span {
		display: block;
		z-index: 10;
		position: relative;
		display: table;
		margin: 0 auto;

		&:after{
			content:"";
			height: 2px;
			width: 100%;
			background: rgba(255, 255, 255, 0);
			position: absolute;
				bottom: 0;
				left: 0;
		}

		img {
			display: block;
			margin: 0 auto 3px auto;
			width: 48px;
		}
	}
}

.cabotlabs .content-carousel {
	margin-bottom: 40px;
}

.cabot-labs{
	padding: 132px 0 160px 0;
	text-align: center;
	margin: 0 auto;
	font-size: 24px;
	color: #6e7072;
	line-height: 1.3em;
	position: relative;
	background: url(../../images/labs-bg.png) top left;
	background-attachment: fixed;

	a,
	a:link,
	a:active,
	a:visited {
		color: #6e7072;

		&:hover { 
			color: #6e7072;
			text-decoration: none; 
		}
	}
	
	&:before {
		content: "";
		width: 4px;
		background: url(../../images/pattern_dots_grey.png) top center repeat-y;
		background-attachment: fixed;
		height: 94px;
		position: absolute;
		left: 50%;
		top: 0;
		margin-left: -2px;
		z-index: 10;
	}


	
	.wrapper {
		max-width: 540px; 
	}

	.arrow{
		position: absolute;
		top: 100px;
		left: 50%;
		margin-left: -10px;
	}


	h2 {
		font-family: "BreuerTextLight", helvetica, Arial, sans-serif;
		font-weight: normal;
		font-size: 40px;
		color: #000000;
		margin-bottom: 40px;

		span {
			font-size: 33px;
		}

		&:hover {
			cursor: pointer;
			
			span {
				border-bottom: 2px solid;
				margin: 14px auto -14px auto;
				padding: 0 0 8px 0;
			}
		}
	}

	a.explore-cabotlabs {
		display: table;
		background: rgba(0, 0, 0, .5);
		border-radius: 3px;
		border: 2px solid rgba(0, 0, 0, .6);
		padding: 13px 15px 14px 43px;
		color: #ffffff !important;
		text-transform: uppercase; 
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		font-size: 17px;
		line-height: 1;
		margin: 0 auto;
		position: relative;
		margin-top: 40px;
		transition: all linear 100ms;

		&:hover{ 
			color: #ffffff !important;
			text-decoration: none !important;
			background: rgba(0, 0, 0, .65);
			border: 2px solid rgba(0, 0, 0, .75);
			transition: all linear 100ms;
		}

		img {
			position: absolute;
			top: 6px;
			left: 6px;
			width: 32px;
		}
	}

	.content-carousel {
		margin-bottom: 40px;
	}

	.content-carousel.stacked {
		margin-bottom: 0;
	}
}

.flex-direction-nav {
	@include respond-max(400px) {
		display: none;
	}
}

.video-modal {
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px; 
	background: #191818;
	z-index: 100;
	display: none;

	video {
		width: 100%;
	}
}

body.modal-open {
    overflow: hidden;
}




// Industry landing page ---------------------------------------------------------------- //




.landing-header.industry {
	height: auto;
	min-height: 472px;
	padding-top: 60px;


	@media screen and (max-width: $breakpoint-medium) {
		min-height: 0;
		padding-top: 30px;
		padding-bottom: 40px;
	}

	@media screen and (max-width: $breakpoint-small) {
		
	}


	&.open {
		.header-background {
			opacity: 0;
			transition: opacity 300ms linear;
		}
	}

	&.transportation {
		background-color: rgba(0, 0, 0, .9)
	}

	.header-background {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: right top;
		background-repeat: no-repeat !important;
		background-size: 100%;
		background-attachment: fixed;
		opacity: 1;
		transition: opacity 300ms linear;
		z-index: -1;

		@media screen and (max-width: 1280px) {
			background-size: auto 610px;
		}

		@media screen and (min-width: 1600px) {
			background-size: cover;
			background-position: center;
			background-attachment: scroll;
		}

		@media screen and (max-width: $breakpoint-medium) {
			opacity: .2;
		}
	}

	.wrapper {
		height: auto;
		width: 100%;
		
		h1,
		p {
			width: 480px;
			transition: all linear 300ms;
			
			@media screen and (max-width: $breakpoint-medium) {
				width: 100%;
			}
		}

		&.open {
			h1,
			p {
				width: 100%;
				transition: all linear 300ms;
			}
		}
	}
	
	.advancing-sublevel-nav {
		display: block;
		width: 70px;
		border-bottom: 2px solid rgba(255, 255, 255, .1);
		line-height: 0;

		&:hover {
			border-bottom: 2px solid rgba(255, 255, 255, .5);			
		}

		&.open {
			img.dropdown-toggle {
				transform: rotate(180deg);
				transition: all ease-in-out 300ms;
			}
		}

		img.industry-icon {
			width: 48px;
			margin-bottom: 11px;
		}

		img.dropdown-toggle {
			float: right;
			margin: 25px 4px 0 0;
			transform: rotate(0deg);
			transition: all ease-in-out 300ms;
		}
	}

	.more-wrapper{

		display: none;
		padding-bottom: 60px;
		
		&.visible {
			opactiy: 1;
			transition: all linear 300ms;
		}
		h4 {
			color: #ffffff;
			background: none;
			display: block;
			font-family: "BreuerTextRegular", helvetica, arial;
			font-size: 41px;
			font-weight: normal;
			margin: 0 0 8px 0;
			text-transform: uppercase;
			padding: 24px 0 0 0;
			position: static;
			bottom: 0;
			left: 25px;
			transition: left ease 300ms;
			transition: left ease 300ms;
		}

		.close {
			display: block;
			color: #ffffff;
			text-decoration: none;
			float: right;
			font-size: 30px;
		}

		a.less {
			display: table;
			color: #64cade;
			font-size: 19px;
			border-bottom: 1px solid rgba(100, 202, 222, .3);
			position: relative;
			margin-left: 17px;

			&:before {
				content: "-";
				position: absolute;
				left: -17px;
				top: 0px;
				-webkit-font-smoothing: antialiased;
			}

			&:hover {
				text-decoration: none;
				border-bottom: 1px solid rgba(100, 202, 222, 1);
			}
		}
	}
	
	.industry-nav{
		position: absolute;
		z-index: 100;
		top: 55px;
		left: 21px;
		display: none;
		padding:4px;
		background: rgba(0, 0, 0, .3);

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				margin: 0;
				padding: 0;

				a{
					font-size: 19px;
					display: block;
					padding: 13px 10px;
					color: #fff;
					width: 230px;
					font-family: "BreuerTextLight", helvetica, arial;
					position: relative;
					opacity: .95;
					background: url(../../images/pattern_tile4.png) top left repeat;

					&:hover {
						opacity: 1;
						text-decoration: none;
					}

					&.active:after {
						content:"";
						display: block;
						position: absolute;
						right: 15px;
						top: 21px;
						width: 13px;
						height: 13px;
						background: url(../../images/icon_white_check.png) center no-repeat;
					}

					span {
						display: inline-block;
						margin-top: 1px;
					}

					img {
						width: 30px;
						margin: 0 10px 0 0;
						float: left;
					}

					&.cabotlabsnav {
						background-color: #fff !important;
						span {
							color: #000;
						}
					}
				}
			}
		}
	}
	
	h1 {
		color: #ffffff;
		background: none;
		display: block;
		font-family: "BreuerTextRegular", helvetica, arial;
		font-size: 41px;
		font-weight: normal;
		margin: 0 0 8px 0;
		text-transform: uppercase;
		padding: 24px 0 0 0;
		position: static;
		bottom: 0;
		left: 25px;
		transition: left ease 300ms;
		transition: left ease 300ms;
		cursor: default;

		@media screen and (max-width: $breakpoint-medium) {
			font-size: 35px;
		}

		@media screen and (max-width: $breakpoint-small) {
			font-size: 30px;
		}


	}

	p {
		color: #e0e0e0;
		font-size: 27px;
		font-family: "BreuerTextLight", helvetica, arial;
		-webkit-font-smoothing: antialiased;
		margin: 0 0 20px 0;
		line-height: 1.35em;

		@media screen and (max-width: $breakpoint-medium) {
			font-size: 24px;
		}

		@media screen and (max-width: $breakpoint-small) {
			font-size: 16px;
		}

	}

	a.more {
		display: table;
		color: #64cade;
		font-size: 19px;
		border-bottom: 1px solid rgba(100, 202, 222, .3);
		position: relative;
		margin-left: 17px;

		&:before {
			content: "+";
			position: absolute;
			left: -17px;
			top: 0px;
			-webkit-font-smoothing: antialiased;
		}

		&:hover {
			text-decoration: none;
			border-bottom: 1px solid rgba(100, 202, 222, 1);
		}
	}
}

.content-carousel.one-col .grid-layout {
	// !!pcb this is a hack to override the default grid column width because we
	// want to use the same markup for both one column and multicolumn layouts.
	&.card .col6 {
		width: 47.5%;
		&:first-child {
			width: 47.5%;
			margin-left: 5%;
		}
	}

}

.content-carousel.split-view-module .flex-direction-nav {
	position: absolute;
	top: 250px;
}

.cabotlabs #envelope {
	background: url(../../images/cabotlabs_section_background_tile.png) #fff;
	background-attachment: fixed;
}

.document-scid, .document-language {
	display: none;
}

// Touch screen tablet devices
.mod-touch {
	.advancing-landing-video {
		video {
			position: relative;
			z-index: -10;
		}

		&.video-playing {
			height: 500px;

			.full {
				height: 500px;
				z-index: 1;
			}
		}
	}
}