﻿
#map {
	width: 100%;
	height: 350px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin: 3em 0 0 0;
	cursor: default;
}

#office-type-filter-container {
	background-color: #fff;

	&.sticky {
		padding-top: 60px;

		@include respond-max(700px) {
			padding-bottom: 25px;
			padding-top: 25px;
		}
	}
}

#office-type-filter {
	list-style-type: none;
	background: none;
	margin: 0 0 2em 0;
	padding: 0;

	@include respond-max(990px) {
		width: 700px;
	}

	@include respond-max(700px) {
		display: none;
	}

	li {
		background: none;
		display: inline-block;
		margin: 0 24px 0 0;
		padding: 0;

		a {
			padding: 10px 0 10px 25px;
			color: #000;
			font-size: 0.95em;

			&.active {
				color: #db371a;
			}

		}
	}
}

.ww-location-dd {
	display: none;

	@include respond-max(700px) {
		display: block;
		margin-bottom: 45px;
	}
}

.sticky .ww-location-dd {
	margin-bottom: 0;
}

.office-type-all {
	background: url(../../images/locations/map_filter_all_black@2x.png) 0px 7px no-repeat;
	background-size: 24px 24px;
}
.office-type-all.active {
	background: url(../../images/locations/map_filter_all_red@2x.png) 0px 7px no-repeat;
	background-size: 24px 24px;
}

/* Manufacturing */
.office-type-14ae9cd8-334f-45f7-951b-fa86dfbada53 {
	background: url(../../images/locations/14ae9cd8-334f-45f7-951b-fa86dfbada53_black@2x.png) 0px 7px no-repeat;
	background-size: 24px 24px;		
}
.office-type-14ae9cd8-334f-45f7-951b-fa86dfbada53.active {
	background: url(../../images/locations/14ae9cd8-334f-45f7-951b-fa86dfbada53_red@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}

/* HQ & Regional offices*/
.office-type-f65fe410-d85a-40fb-aef3-df3031a1ca06,
.office-type-b774e19d-4205-4fd7-a34a-6a5e82b2d839,
.office-type-87956b3b-4e47-4fc3-9f21-cd089151bcc3,
.office-type-b784e19d-4205-4fd7-a34a-6a5e82b2d839 {
	background: url(../../images/locations/f65fe410-d85a-40fb-aef3-df3031a1ca06_black@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}
.office-type-f65fe410-d85a-40fb-aef3-df3031a1ca06.active,
.office-type-b774e19d-4205-4fd7-a34a-6a5e82b2d839.active,
.office-type-87966b3b-4e47-4fc3-9f21-cd089151bcc3.active,
.office-type-b784e19d-4205-4fd7-a34a-6a5e82b2d839.active {
	background: url(../../images/locations/f65fe410-d85a-40fb-aef3-df3031a1ca06_red@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}

/* R&D */
.office-type-c08d1b86-43d9-4159-beb2-8959a7f33a89 {
	background: url(../../images/locations/c08d1b86-43d9-4159-beb2-8959a7f33a89_black@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}
.office-type-c08d1b86-43d9-4159-beb2-8959a7f33a89.active {
	background: url(../../images/locations/c08d1b86-43d9-4159-beb2-8959a7f33a89_red@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}

/* Sales */
.office-type-ce9017f2-936e-4966-bd99-300ee022e323,
.office-type-138df8ba-59ec-4d97-9d6f-fc514a1abfb1,
.office-type-dd39dc41-0e3a-498a-a844-ef17e2b1eb49 {
	background: url(../../images/locations/dd39dc41-0e3a-498a-a844-ef17e2b1eb49_black@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}
.office-type-ce9017f2-936e-4966-bd99-300ee022e323.active,
.office-type-138df8ba-59ec-4d97-9d6f-fc514a1abfb1.active,
.office-type-dd39dc41-0e3a-498a-a844-ef17e2b1eb49.active {
	background: url(../../images/locations/dd39dc41-0e3a-498a-a844-ef17e2b1eb49_red@2x.png) 0px 7px no-repeat;		
	background-size: 24px 24px;		
}

.office-types {
	.location-office-type-icon {
		width: 24px;
		height: 28px;
		display: inline-block;
		margin-right: 1px;
	}
	.office-type-label {
		display: inline-block;
	}
}

.main-content .location-table {
	margin: 0 auto 3em auto;
	text-align: left;
	width: 100%;

	caption {
		background-color: #F3F4F2;
		width: 100%;
		font-size: 1.5em;
		padding: 10px 20px;
		text-align: left;
		text-transform: uppercase;
		font-size: 1.1em;
		font-weight: 700;
	}

	th {
		padding: 10px;
		background-color: #5FCAE0;
		text-transform: uppercase;

		&.country {
			width: 20%;
		}
		&.city {
			width: 20%;
		}
		&.business-name {
			width: 50%;
		}
		&.office-type {
			width: 10%;
		}
	}

	tr:hover {
		background-color: #eee;
		cursor: pointer;
	}

	td {
		padding: 5px 10px;
		font-weight: 500;
		vertical-align: middle;

		&.office-types {
			text-align: right;
		}
	}
}


#current-region {
	position: relative;
	height: 50px;
	top: -50px;	
	left: 15px;
	width: 250px;

	span {
		border-radius: 5px;
		color: #333;
		display: inline-block;	
		font-size: 0.9em;
		padding: 5px;
	}
}

.sticky {
	position: fixed;
	width: 902px;
	top: 0;
	z-index: 100;
	border-top: 0;
	padding-top: 15px;
}

#popup-template {
	display: none;
}

.leaflet-popup-content-wrapper {
	border-radius: 6px;
	border: 1px solid #ECECEC;
}

.leaflet-popup-content {
	width: 210px;
}

.leaflet-popup-tip-container {
	top: -1px;
}


.leaflet-container .location-popup {
	h4 {
		font-size: 16px; 
		font-weight: normal;
		margin-bottom: 0;
	}

	a, a:hover {
		color: #db371a;
		font-size: 1.1em;
	}
}

.leaflet-container img.leaflet-marker-icon {
	cursor: pointer;
}

.location-popup .office-types {
	margin-bottom: 3px;
}

.leaflet-container .popup-contact-info {
	font-size: 1.1em;
	line-height: 1.3em;
}

.leaflet-container .leaflet-popup-close-button {
	background-image: url('../images/locations/map_bubble_close.png');
	background-size: 24px 24px;
	background-position: 0 0;
	margin-top: 5px;
	margin-right: 1px;

	&:hover {
		background-color: #fff;
	}

}