﻿

// Fonts
$primary-font: "BreuerTextLight", helvetica, Arial, sans-serif;
$secondary-font: Georgia, "Times New Roman", serif;


// Colors
$primary-text-color: #000000;  // dark grey/light black - base text color
$secondary-text-color: #e03314;  // orange
$tertiary-text-color: #FFFFFF;  // white

$primary-accent-color: #e03314;  // blue - also primary link color
$secondary-link-color: #636363; // medium grey - used in secondary nav
$tertiary-link-color: #FFFFFF; // white
$navigation-link-color: #95DEF4;  // light blue - used for page descriptions in dropdown nav

$primary-button-color: #75B639; // green
$secondary-button-color: #989898; // grey

$secondary-background: #ededed;

$default-border-radius: 5px 5px 5px 5px;



// Breakpoints
$breakpoint-first:1023px;
$breakpoint-large:950px;
$breakpoint-header:770px;
$breakpoint-medium:700px;
$breakpoint-small:400px;
$breakpoint-index-table: 520px;






