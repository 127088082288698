.featured-cards {
  color: $black;
}
.featured-cards__title {
  font-size: 33px;
  text-transform: uppercase;
  font-family: $bold-font;
  padding: 64px 0;

  &:after {
    display: block;
    width: 34px;
    height: 4px;
    background-color: $cabot-red;
    content: "";
    margin-top: 16px;
  }
}
.featured-cards__card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50vw;
  max-height: 600px;
  position: sticky;
  top: 0;
  background-color: #fff;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media ($bp-medium-max) {
    &,
    &:nth-child(even) {
      flex-direction: column;
      height: auto;
      max-height: none;
      position: relative;
    }
  }
}
.featured-cards__card-image {
  width: 50%;
  height: 50vw;
  max-height: 600px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ($bp-medium-max) {
    width: 100%;
    height: 100vw;
    max-height: none;
  }
}
.featured-cards__card-content {
  display: flex;
  justify-content: center;
  height: 100%;
  width: calc(100% - 80px);
  padding: 0 40px;
  flex-direction: column;
  align-items: flex-start;

  @media ($bp-medium-max) {
    height: auto;
    width: 100%;
    padding: 24px 24px 48px;
  }
}
.featured-cards__card-title {
  font-size: 32px;
  font-family: $bold-font;
  margin-bottom: 32px;
  max-width: 100%;

  @media ($bp-medium-max) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
.featured-cards__card-description {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 32px;
  max-width: 100%;

  @media ($bp-medium-max) {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
