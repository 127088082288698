.goals-listing {
  position: relative;

  &__title {
    @extend .underlined-header;
    margin-bottom: 40px;

    @media ($bp-medium-max) {
      font-size: 24px;
    }
  }

  &__listing-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 40px;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    @media ($bp-medium-max) {
      flex-direction: column;
    }
  }

  &__items-wrapper {
    width: 55%;
    margin-left: 5%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    .goals-listing__listing-wrapper:nth-child(odd) & {
      margin-left: 0;
      margin-right: 5%;
    }

    @media ($bp-medium-max) {
      .goals-listing__listing-wrapper:nth-child(odd) &,
      .goals-listing__listing-wrapper:nth-child(even) & {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__featured-image-wrapper {
    width: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    @media ($bp-medium-max) {
      display: none;
    }
  }
  &__featured-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 300ms linear;
    opacity: 1;
  }
  &__featured-image-caption {
    opacity: 1;
    transition: all 300ms linear;
  }

  &__image-is-hidden .goals-listing__featured-image,
  &__image-is-hidden .goals-listing__featured-image-caption {
    opacity: 0;
  }

  &__goal-title {
    font-size: 22px;
    font-family: $bold-font;
    font-weight: 500;
    letter-spacing: 0.23px;
    line-height: 30px;
  }
  &__goal-description {
    margin-bottom: 40px;

    @media ($bp-medium-max) {
      margin-bottom: 30px;
    }
  }

  &__list-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    @media ($bp-medium-max) {
      flex-direction: column;
    }
  }

  &__list-item {
    width: calc(50% - 15px);
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    border-bottom: 2px solid $grey-200;
    margin-bottom: 40px;
    padding-bottom: 10px;

    &--active {
      border-bottom: 4px solid $cabot-red;
      color: $cabot-red;
    }

    @media ($bp-medium-max) {
      width: 100%;
    }
  }

  &__list-item-title {
    font-family: $bold-font;
    font-weight: 500;
    position: relative;
    display: block;

    &:hover {
      cursor: pointer;
    }

    @media ($bp-medium-max) {
      padding-right: 44px;
      &:after {
        transition: all 150ms linear;
        content: url("data:image/svg+xml; utf8, %3Csvg viewBox='0 0 56 56' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill-opacity='.7' fill='%23000' cx='28' cy='28' r='28'/%3E%3Cpath fill='%23FFF' d='M21 16l7 12-7 12h7l7-12-7-12z'/%3E%3C/g%3E%3C/svg%3E");
        display: block;
        position: absolute;
        right: 0;
        top: calc(50% - 16px);
        width: 32px;
        height: 32px;
        transform-origin: center;
      }

      .goals-listing__list-item--active &:after {
        transform: rotate(90deg);
        content: url("data:image/svg+xml; utf8, %3Csvg viewBox='0 0 56 56' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill-opacity='.7' fill='%23ce181e' cx='28' cy='28' r='28'/%3E%3Cpath fill='%23FFF' d='M21 16l7 12-7 12h7l7-12-7-12z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  &__list-item-image {
    display: none;

    .experience-editor & {
      display: block;
    }

    @media ($bp-medium-max) {
      margin-top: 20px;
      margin-bottom: 10px;

      .goals-listing__list-item--active & {
        display: block;
      }
    }
  }

  &__list-item-image-caption {
    color: #000;
    display: none;

    .experience-editor & {
      display: block;
    }

    @media ($bp-medium-max) {
      .goals-listing__list-item--active & {
        display: block;
      }
    }
  }
}
