$promo-media-margin: 100px;

.promo {
  @include component-whitespace();
  align-items: flex-start;
  // background-color: $white;
  background-repeat: no-repeat;
  background-size: 40%;
  color: $cabot-darkest-gray;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.75rem;
  padding: 60px 0;

  // .has-image & {
  //   padding: 190px 135px;
  // }

  &.is-left {
    // background-image: url("../img/promo-background-left.svg");
    // background-position: top right;
    flex-direction: row;

    .promo__media {
      margin-right: $promo-media-margin;
      margin-bottom: 0;
      width: 100%;

      @media screen and (min-width: $breakpoint-large) {
        max-width: 50%;
      }
    }

    .promo__cta {
      text-align: left;
    }
  }

  &.is-right {
    // background-image: url("../img/promo-background-right.svg");
    // background-position: top left;
    flex-direction: row-reverse;

    .promo__media {
      margin-left: $promo-media-margin;
      margin-bottom: 0;
      width: 100%;

      @media screen and (min-width: $breakpoint-large) {
        max-width: 50%;
      }
    }
  }

  &.is-left,
  &.is-right {
    align-items: center;

    &.has-background-color {
      background-image: none;
    }
  }
  img {
    max-width: 100%;
    width: 100%;
  }

  @media ($bp-large-max) {
    &.is-right,
    &.is-left {
      align-items: flex-start;
    }
  }

  @media ($bp-medium-max) {
    &.is-right,
    &.is-left {
      flex-direction: column;

      .promo__media {
        margin-bottom: 1.125rem;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.promo__responsive-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.promo__description {
  font-family: $primary-font;
  max-width: 800px;
  font-size: 16px;
  line-height: 1.8;

  & + .promo__cta {
    margin-top: 56px;
  }
}

.promo__media {
  margin-bottom: 1.125rem;
  width: 100%;
  & iframe {
    max-width: 100%;
  }
}

.promo__content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.promo__title {
  font-size: 33px;
  font-family: $bold-font;
  text-transform: uppercase;

  // &:after {
  //   content: "";
  //   background-color: $cabot-red;
  //   display: block;
  //   height: 4px;
  //   margin-top: 16px;
  //   margin-bottom: 32px;
  //   width: 32px;
  // }
}

a.promo__cta {
  &:hover {
    text-decoration: none;
  }
}
