﻿h3 {
  color: #222222;
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: "BreuerTextRegular", helvetica, arial;
}

.column-toggle {
  display: table;
  margin: 20px auto 0 auto;
  text-align: center;
}

.cta-module {
  .wrapper {
    padding: 0;
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }

  &.one-col {
    padding-bottom: 100px;
    .card {
      @extend .col4;

      .card-bg {
        .icon {
          background: url("../images/triangle-large-bg.png") bottom right;
          height: 130px;
          margin: 0 auto 0 auto;
          padding-top: 12px;
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 75px;

          img {
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    clear: both;
    padding-top: 40px;
  }

  .card.two {
    width: 50%;

    .card-bg {
    }
  }

  .card {
    @extend .col3;
    margin-top: 50px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-top: 0;
      margin-bottom: 20px;
      float: none;
    }

    .card-bg {
      background: #f2f4f3 url("../../images/cta_rollover.png") no-repeat; //Velir updating path
      background-position: left -100% top 0;
      display: block;
      font-size: 14px;
      line-height: 1.4;
      min-height: 240px;
      padding: 30px 60px 19px 19px;
      position: relative;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04) inset;
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04) inset;
      transition: background-position ease-out 600ms;

      @media screen and (max-width: $breakpoint-medium) {
        background-position: left -200% top 0;
      }

      &:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04) inset;
        -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04) inset;
        transition: background-position ease 600ms;
        background-position: left -200px top 0;

        h4 {
          color: #ffffff;
          transition: all ease 300ms;
        }
        a {
          color: #ffffff;
          transition: all ease 300ms;
        }
      }

      @media screen and (max-width: $breakpoint-medium) {
        min-height: 0;
      }
    }

    a {
      color: #ce181e;
      background: url("../../images/dottedline.png") top left repeat-x; //Velir updated path
      display: block;
      padding: 10px 0;

      &:nth-of-type(2) {
        //padding-top: 0;
        //background: none;
      }
    }

    .icon {
      background: url(../../images/triangle-bg.png) bottom right; //Velir updated path
      height: 100px;
      margin: 0 auto 0 auto;
      padding-top: 12px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 61px;

      img {
        position: absolute;
        bottom: 6px;
        right: 6px;
        width: 32px;
      }
    }

    h4 {
      color: #000000;
      font-family: "BreuerTextRegular", helvetica, arial;
      font-weight: normal;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 25px;
      margin-top: 0;
      text-transform: uppercase;
    }
  }
}

.content-carousel h4 {
  line-height: 1.3;
}

.cta-module.one-col .wrapper {
  padding-left: 25px;
  padding-right: 25px;
}

.document-module {
  margin: 0 0 25px 0;

  h3 {
    font-size: 20px;
    margin-bottom: 23px;
    text-align: left;

    &:before {
      background: url("../images/icon_button_download_blue_32.png") center
        no-repeat;
      background-size: 32px 32px;
      content: "";
      display: block;
      float: left;
      height: 32px;
      margin: -1px 3px 0 0;
      width: 32px;
    }
  }

  h4 {
    margin: 0 0 0 28px;
    font-size: 17px;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-weight: normal;

    a {
      background-image: url("../images/icon_download@2x_24.png");
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      height: 24px;
      margin-left: -26px;
      padding-left: 33px;
    }
  }

  .description {
    font-family: "BreuerTextItalic", helvetica, arial;
    font-size: 14px;
    font-style: italic;
    padding: 2px 0 20px 35px;
  }
}

#modules-container {
  position: relative;
}

.promo-module {
  background-size: cover;
  text-align: center;
  margin-bottom: 40px;

  p {
    letter-spacing: 0;
  }

  &.one-col {
    height: auto;
    margin-bottom: 0;

    &.solid {
      padding: 50px;

      @include respond-max(540px) {
        padding: 0;
      }
    }

    @media screen and (max-width: $breakpoint-medium) {
      padding: 46px 0 46px 0;
    }
  }

  .promotion-card {
    padding: 55px 45px;

    @media screen and (max-width: $breakpoint-medium) {
      padding: 40px 25px;
    }
  }

  &.image .promotion-card {
    background-size: cover;
    background-position: center;

    p {
      font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
    }
  }

  &.image.light-text {
    a.link {
      background: rgba(50, 50, 50, 0.8);

      &:hover {
        background: rgba(50, 50, 50, 0.95);
      }
    }
  }

  &.solid.gray {
    background-color: #6e7072;

    a.link {
      background: rgba(255, 255, 255, 0.9);
      color: #000;
      border: 2px solid #fff;

      &:hover {
        background: rgba(255, 255, 255, 1);
      }

      &:before {
        background: url("../images/icon_button_link_black_32.png") no-repeat;
        background-size: 32px 32px;
        position: absolute;
        left: 6px;
        top: 6px;
      }

      &.video:before {
        background: url("../images/icon_button_video_black_32.png") no-repeat;
        background-size: 32px 32px !important;
        content: "";
        width: 32px;
        height: 32px;
        display: inline-block;
      }
    }
  }

  &.transparent,
  &.transparent h3 {
    color: #db371a;

    a {
      background: rgba(226, 62, 33, 0.9);
      border: 2px solid rgba(226, 62, 33, 1);
      padding: 7px 16px 9px 16px;
      color: #fff;
      letter-spacing: 0.5px;

      &:hover {
        text-decoration: none;
        background: rgba(226, 62, 33, 1);
      }
    }
  }

  &.dark-text {
    color: #000;

    h3,
    p {
      color: #000;
    }

    a {
      background: rgba(255, 255, 255, 0.8);
      background-size: 20px;
      border: 2px solid #fff;
      padding: 7px 16px 9px 16px;
      color: #000;
      letter-spacing: 0.5px;

      &:hover {
        color: #000;
        text-decoration: none;
        background: rgba(255, 255, 255, 1);
        background-size: 20px;
      }
    }

    &.file:before {
      background: url("../images/icon_button_document_white_16.png") no-repeat;
      background-size: 16px 16px;
    }

    a.link:before {
      background: url("../images/icon_button_link_black_16.png") no-repeat;
      background-size: 16px 16px;
    }
  }

  h3 {
    color: #fff;
    font-size: 33px;
    font-family: "BreuerTextRegular", helvetica, arial;
    margin-bottom: 12px;

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 30px;
      line-height: 1.1em;
    }
  }

  p {
    color: #ffffff;
    font-size: 22px;
    margin: 0 auto;
    max-width: 720px;
    line-height: 1.5em;
    margin-bottom: 27px;

    @media screen and (max-width: $breakpoint-medium) {
      padding: 0;
      font-size: 16px;
      margin-bottom: 25px;
    }
  }

  a.link {
    display: inline-block;
    background: rgba(224, 51, 20, 0.9);
    color: #ffffff;
    padding: 13px 15px 14px 43px;
    border-radius: 3px;
    border: 2px white solid;
    line-height: 1;
    font-size: 17px;
    position: relative;
    text-transform: uppercase;
    font-family: "BreuerTextRegular", helvetica, arial;
    margin-top: 5px;

    @media screen and (max-width: $breakpoint-small) {
      padding: 13px 15px 14px 15px;
    }

    &:before {
      @media screen and (max-width: $breakpoint-small) {
        display: none !important;
        padding: 13px 15px 14px 15px;
      }
    }

    &.file:before,
    &.link:before {
      content: "";
      display: inline-block;
      height: 32px;
      width: 32px;
    }

    &.link:before {
      background: url("../images/icon_button_link_white_32.png") no-repeat;
      background-size: 32px 32px;
      position: absolute;
      left: 6px;
      top: 6px;
    }

    &.file:before {
      background: url("../images/icon_button_document_white_32.png") no-repeat;
      background-size: 32px 32px !important;
      position: absolute;
      left: 6px;
      top: 6px;
    }

    &.video:before {
      background: url("../images/icon_button_video_white_32.png") no-repeat;
      background-size: 32px 32px !important;
      content: "";
      width: 32px;
      height: 32px;
      display: inline-block;
    }

    &:hover {
      color: #ffffff;
      background: rgba(224, 51, 20, 1);
      text-decoration: none;
    }
  }
}

.content-carousel.one-col {
  @include respond-max($breakpoint-medium) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.quote-module {
  color: white;
  background-size: cover;
  position: relative;
  margin: 40px 0;

  .graphic {
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 44%;

    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }

  .content {
    position: static;
    padding: 43px 43px 33px 43px;
    margin: 0 auto;
    font-size: 22px;
    line-height: 1.5em;
    font-family: "BreuerTextLight", helvetica, arial;
    text-align: center;
    width: 100%;

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 16px;
    }

    &.has-graphic {
      width: 52%;
      margin: 0;
      margin-left: 44%;
      text-align: left;
      font-size: 16px;
      line-height: 1.7em;

      .btn {
        margin: 27px 0 0 0;
      }

      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        margin-left: 0;
        padding: 35px;
        text-align: center;
      }
    }

    .btn {
      display: inline-block;
      background: rgba(224, 51, 20, 0.9);
      color: white;
      text-decoration: none;
      font-size: 17px;
      line-height: 1;
      margin: 30px auto 10px auto;
      padding: 13px 15px 14px 43px;
      position: relative;
      text-transform: uppercase;
      border-radius: 3px;
      border: 2px white solid;
      font-family: "BreuerTextRegular", helvetica, arial;

      @media screen and (max-width: $breakpoint-medium) {
        margin: 27px auto 0 auto;
      }

      @media screen and (max-width: $breakpoint-small) {
        padding: 13px 15px 14px 15px;
        width: auto;
      }

      &:after {
        content: "";
        height: 32px;
        width: 32px;
        background: url("../images/icon_button_generic_white_32.png") no-repeat !important;
        background-size: 32px 32px !important;
        display: inline-block;
        position: absolute;
        left: 6px;
        top: 6px;

        @media screen and (max-width: $breakpoint-small) {
          display: none !important;
          float: right;
          margin-top: 10px;
        }
      }

      &:hover {
        background: rgba(224, 51, 20, 1);
      }
    }
  }
}

.article-module {
  background: #e9e9e9;
  text-align: center;
  min-height: 700px;
  position: relative;
  background: #e9e9e9;
  text-align: center;
  min-height: 700px;
  position: relative;
  background: center;
  background-size: cover;

  @media screen and (max-width: $breakpoint-large) {
    min-height: 550px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    min-height: 0px;
  }

  .wrapper {
    position: relative;

    @media screen and (max-width: $breakpoint-large) {
      position: static;
    }

    @media screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }
  }

  .story-teaser {
    width: 45.7%;
    float: none;
    color: #ffffff;
    padding: 37px 45px 45px 45px;
    background: rgba(206, 24, 30, 0.95);
    position: absolute;
    left: 25px;
    top: 60px;

    @media screen and (max-width: $breakpoint-large) {
      width: auto;
      left: 0;
      top: 0px;
      right: 0px;
      bottom: 0px;
      padding: 37px 25px 25px 25px;
    }

    @media screen and (max-width: $breakpoint-medium) {
      padding: 37px 25px 45px 25px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      position: static;
    }

    .flex-control-paging li a {
      border: 3px #ff303c solid;
    }

    .flex-control-paging li a:hover,
    .flex-control-paging li a.flex-active {
      border: 3px #ff303c solid;
    }

    a.more {
      display: table;
      color: #ffffff;
      font-size: 18px;
      border-bottom: 1px solid rgba(252, 175, 23, 0.5);
      position: relative;
      margin: 0 auto;

      &:before {
        content: "+";
        position: absolute;
        left: -17px;
        top: 0px;
        -webkit-font-smoothing: antialiased;
      }

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid rgba(252, 175, 23, 1);
      }
    }
  }

  h3 {
    color: #ffffff;
    font-size: 36px;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-weight: normal;
    margin: 0 0 18px 0;
  }

  h4 {
    font-size: 27px;
    font-family: "BreuerTextRegular", helvetica, arial;
    font-weight: normal;
    padding: 17px 40px 11px 40px;
    margin: 0;
    line-height: 1.3em;

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 24px;
    }
  }

  .article {
    background: rgba(206, 24, 30, 0.95);
    display: none;
    color: #ffffff;
    text-align: left;
    font-size: 19px;
    padding: 43px 0 55px 0;
    position: relative;
    z-index: 1;

    @include respond-max(1080px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 16px;
    }

    .line-tile {
      background: url("../images/pattern_tile3.png") top left repeat;
      background-attachment: fixed;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.1;
    }

    .article-wrapper {
      @extend .wrapper;
      padding: 0 40px;
      position: relative;
      z-index: 10;
      opacity: 0;
      transition: all linear 300ms;

      &.visible {
        opacity: 1;
        transition: all linear 300ms;
      }
    }

    .close {
      display: block;
      color: #ffffff;
      text-decoration: none;
      float: right;
      font-size: 30px;
    }

    h3 {
      margin: 0 0 -3px 0;
      padding: 26px 0 0 0;
      text-align: left;
      color: #a10000;
      font-size: 39px;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: 30px;
      }
    }

    h4 {
      margin: 0 0 50px 0;
      text-align: left;
      font-size: 39px;
      font-family: "BreuerTextRegular", helvetica, arial;
      text-transform: uppercase;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: 30px;
      }
    }

    p {
      margin: 0 0 24px 0;
    }

    a {
      border-bottom: 1px solid rgba(252, 175, 23, 0.5);
      color: #fff;
      padding-bottom: 4px;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid rgba(252, 175, 23, 1);
      }
    }

    a.close {
      border-bottom: 0;

      @include respond-max($breakpoint-medium) {
        position: absolute;
        right: 0;
        top: -26px;
      }
    }

    a.less {
      display: table;
      color: #ffffff;
      font-size: 18px;
      border-bottom: 1px solid rgba(252, 175, 23, 0.5);
      position: relative;
      margin-left: 17px;
      text-decoration: none;

      &:before {
        content: "-";
        position: absolute;
        left: -17px;
        top: 0px;
        -webkit-font-smoothing: antialiased;
      }

      &:hover {
        border-bottom: 1px solid rgba(252, 175, 23, 1);
      }
    }
  }
}

.content-carousel.graph-module .flex-direction-nav {
  @include respond-max($breakpoint-medium) {
    display: none;
  }
}

.applications-module {
  background: #64cade;
  position: relative;
  padding: 125px 0 84px 0;
  background-size: cover;

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: center;
    background-size: cover !important;
    opacity: 0;
    transition: opacity 200ms linear;

    &.active {
      opacity: 0.05;
      transition: opacity 200ms linear;
    }
  }
  .wrapper {
    z-index: 2;
    position: relative;
  }

  h2 {
    text-transform: uppercase;
    font-size: 33px;
    font-weight: normal;
    font-family: "BreuerTextRegular", helvetica, arial;
    margin: 0 0 20px 0;

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 30px;
      line-height: 1.3em;
    }
  }

  .applications-list {
    @media screen and (max-width: $breakpoint-medium) {
      margin-bottom: 35px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        list-style-type: none;

        &:last-child {
          border: none;
        }

        a {
          display: block;
          color: #000;
          font-size: 19px;
          padding: 6px 15px;

          &.active,
          &:hover {
            color: #fff;
            background: #000;
            text-decoration: none;
          }
        }
      }
    }
  }

  .application-descriptions {
    .application-description {
      padding-left: 15px;

      h4 {
        font-size: 27px;
        font-family: "BreuerTextLight", helvetica, arial;
        font-weight: normal;
        margin: 0px 0 21px 0;

        @media screen and (max-width: $breakpoint-medium) {
          margin: 0px 0 10px 0;
        }
      }

      p {
        font-size: 19px;
        margin: 0 0 27px 0;

        @media screen and (max-width: $breakpoint-small) {
          font-size: 16px;
        }
      }

      a {
        background: rgba(255, 255, 255, 0.8);
        border: 2px solid rgba(255, 255, 255, 0.9);
        border-radius: 3px;
        color: #000;
        display: inline-block;
        font-family: "BreuerTextRegular", helvetica, arial;
        font-size: 17px;
        line-height: 1;
        padding: 13px 15px 14px 43px;
        position: relative;
        text-transform: uppercase;
        transition: all 200ms linear;

        &:hover {
          text-decoration: none;
          transition: all 200ms linear;
          background: rgba(255, 255, 255, 1);
          border: 2px solid rgba(255, 255, 255, 1);
        }

        &:before {
          background: url("../images/icon_button_generic_black_32.png")
            no-repeat;
          background-size: 32px 32px !important;
          content: "";
          display: inline-block;
          height: 32px;
          width: 32px;
          position: absolute;
          left: 6px;
          top: 6px;
        }
      }
    }
  }
}

.content-carousel.stacked.story-module.one-col {
  padding: 0;
}

.content-carousel.flexslider-one-col.module.story-module.one-col {
  padding: 0;

  .wrapper {
    .controls-nav {
      display: none;
    }
  }
}

.story-module {
  .controls-nav {
    margin-bottom: 8px;
  }

  &.flexslider-one-col {
    .flex-control-paging {
      margin: 0 auto;
      position: relative;
      left: -250px;
      top: 160px;
      z-index: 100;

      @media screen and (max-width: $breakpoint-large) {
        left: 0;
        top: 100px;
      }

      @media screen and (max-width: $breakpoint-medium) {
        left: 0;
      }

      li a {
        border: 3px #ff303c solid;
      }

      li a:hover,
      li a.flex-active {
        background: #ff303c;
        border: 3px #ff303c solid;
      }
    }
  }

  &.one-col {
    .wrapper {
      max-width: 100%;
      padding: 0;
    }

    .flex-direction-nav {
      position: relative;
      margin: 0 auto;
      width: 430px;
      top: 177px;
      left: -254px;
      z-index: 100;

      @media screen and (max-width: $breakpoint-large) {
        width: 100%;
        top: 195px;
      }

      @include respond-max(1040px) {
        left: -24.8%;
        width: 42.5%;
      }

      @include respond-max(950px) {
        left: 0;
        top: 100px;
        width: 95%;
      }

      @include respond-max(480px) {
      }

      li {
        display: inline-block;
      }

      .flex-next {
        //right: 52.3%;
        right: 0;

        @media screen and (max-width: $breakpoint-large) {
          right: 1%;
        }
      }

      .flex-prev {
        left: 0;
      }
    }

    .article-module {
      .article {
        .article-wrapper {
          max-width: 1024px;

          h4 {
            padding: 0;
          }
        }
      }
    }

    .grid-layout {
      .slides {
        padding: 0;
        width: 100%;
      }
    }

    .inner-wrapper {
      width: 1024px;
    }

    .story-teaser {
      p {
        color: #fff;
      }
    }

    .article-wrapper {
      &.visible {
        p {
          color: #fff;
        }
      }
    }
  }
}
.split-view-module {
  background: #f2f4f3;
  padding: 85px 0;

  h4 {
    text-transform: none;
    color: #e23e21;
    font-family: "BreuerTextLight", helvetica, arial;
    text-align: left;
    padding: 4px 0 11px 0;
    font-size: 27px;
    margin: 0;
  }

  a img {
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
}

.event-module {
  @include respond-max(768px) {
    padding: 0 25px;
  }

  .event-card img {
    @include respond-max(700px) {
      float: left;
      padding-bottom: 15px;
    }
  }
}

.accordion-group {
  &.page-editor {
    .accordion {
      .body {
        display: block;
      }
    }
  }

  h2 {
    clear: both;
    margin-top: 12px;
    font-size: 19px;
    letter-spacing: 0.015em;
    font-family: BreuerTextLight;
    font-weight: normal;
    margin-bottom: 0px;
  }

  .accordion {
    a {
      position: relative;
    }

    &.open {
      h2 a {
        &:before {
          //content: "–";
          content: "-";
          width: 30px;
          display: inline-block;
          position: absolute;
          left: -19px;
          font-size: 14px;
          font-family: verdana;
          bottom: 1px;
        }
      }
    }

    h2 a {
      &:before {
        content: "+";
        width: 30px;
        display: inline-block;
        position: absolute;
        left: -19px;
        font-size: 14px;
        font-family: verdana;
        top: 1px;
      }
    }

    .body {
      display: none;
      padding: 0 0 30px 0;
    }
  }
}

.image-grid-module {
  overflow: visible !important;

  #image-grid-title {
    font-size: 33px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  > .image-grid {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li,
    .grid-inner {
      margin: 0;
      padding: 20px;
      position: relative;

      @media screen and (max-width: 700px) {
        width: 50% !important;
        padding: 10px;
      }

      img:first-child {
        border: 6px solid #fff;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        transform: perspective(1000px) rotateY(0deg);
        -webkit-transform: perspective(1000px) rotateY(0deg);
        transition: all ease-in 300ms;
        -webkit-transition: all ease-in 300ms;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
      }

      .overlay {
        display: block;
        position: absolute;
        background: rgba(226, 62, 33, 0.9);
        color: #fff;
        top: 20px;
        left: 20px;
        bottom: 26px;
        right: 20px;
        padding: 42px 30px;
        font-size: 21px;
        //opacity: 0;
        transition: all ease-in 300ms;
        -webkit-transition: all ease-in 300ms;
        transform: perspective(1000px) rotateY(-180deg);
        -webkit-transform: perspective(1000px) rotateY(-180deg);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;

        h4 {
          color: #fff;
          font-size: 27px;
          margin-bottom: 8px;
          margin-top: -5px;
        }

        @media screen and (max-width: 950px) {
          padding: 35px 20px;
          font-size: 18px;
        }

        @media screen and (max-width: 800px) {
          padding: 20px 20px;
          font-size: 16px;
        }

        @media screen and (max-width: 700px) {
          display: none;
        }

        .bullet {
          margin-right: 10px;
          margin-bottom: 5px;
        }
      }

      ul {
        margin: 8px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
          background: url(../images/diamond-bullet.png) top 13px left no-repeat;
          margin: 0;
          padding: 0 0 0 20px;
        }
      }

      &:hover {
        animation: smallFlip 400ms;
        -webkit-animation: smallFlip 400ms;
      }

      &.flipped {
        animation: flip 400ms;
        -webkit-animation: flip 400ms;
        a {
          cursor: url(../images/video_close.png), auto;
        }
        img:first-child {
          transform: perspective(1000px) rotateY(180deg);
          -webkit-transform: perspective(1000px) rotateY(180deg);
          transition: all ease-in 400ms;
          -webkit-transition: all ease-in 400ms;
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
          border: 0px solid #fff;
        }

        .overlay {
          //opacity: 1;
          transform: perspective(1000px) rotateY(0deg);
          -webkit-transform: perspective(1000px) rotateY(0deg);
          transition: all ease-in 400ms;
          -webkit-transition: all ease-in 400ms;
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
        }
      }
    }
  }

  .careers-cta {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 13px;
    clear: both;

    h4 {
      font-size: 33px;
      font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
      color: rgb(226, 62, 33);
      text-transform: uppercase;
      font-weight: normal;
      margin: 0 0 20px 0;
      padding: 0 25px;
      line-height: 42px;
    }

    a {
      background: rgba(226, 62, 33, 0.9);
      border-radius: 3px;
      border: 2px solid rgba(226, 62, 33, 1);
      font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
      padding: 7px 16px 9px 9px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      display: table;
      margin: 0 auto;
      color: #fff;

      &:hover {
        text-decoration: none;
        background: rgba(226, 62, 33, 1);
      }

      img {
        width: 20px;
        margin-right: 6px;
        position: relative;
        top: 4px;
      }
    }
  }
}

.graph-module {
  .grid-layout {
    .link,
    .file,
    .video {
      display: inline-block;
      background: rgba(230, 106, 47, 0.9);
      color: #ffffff;
      padding: 10px 33px;
      border-radius: 3px;
      border: 2px white solid;
      text-transform: uppercase;
      font-family: "BreuerTextRegular", helvetica, arial;

      &:hover {
        color: #ffffff;
        background: rgba(230, 106, 47, 1);
        text-decoration: none;
      }
    }

    .link:before {
      background: url("../images/icon_button_link_white_16.png") no-repeat;
      background-size: 16px 16px;
      content: "";
      display: inline-block;
      margin-right: 7px;
      height: 16px;
      width: 16px;
    }

    .file:before {
      background: url("../images/icon_button_document_white_16.png") no-repeat;
      background-size: 16px 16px;
      content: "";
      display: inline-block;
      margin-right: 7px;
      height: 16px;
      width: 16px;
    }

    .video:before {
      background: url("../images/icon_video.png");
      content: "";
      width: 23px;
      height: 15px;
      display: inline-block;
      margin-right: 7px;
    }
  }
}

.story-module {
  .grid-layout {
    .link,
    .file,
    .video {
      display: inline-block;
      background: rgba(230, 106, 47, 0.9);
      color: #ffffff;
      padding: 10px 33px;
      border-radius: 3px;
      border: 2px white solid;
      text-transform: uppercase;
      font-family: "BreuerTextRegular", helvetica, arial;

      &:hover {
        color: #ffffff;
        background: rgba(230, 106, 47, 1);
        text-decoration: none;
      }
    }

    .link:before {
      background: url("../images/icon_button_link_white_16.png") no-repeat;
      background-size: 16px 16px;
      content: "";
      display: inline-block;
      margin-right: 7px;
      height: 16px;
      width: 16px;
    }

    .file:before {
      background: url("../images/icon_button_document_white_16.png") no-repeat;
      background-size: 16px 16px;
      content: "";
      display: inline-block;
      margin-right: 7px;
      height: 16px;
      width: 16px;
    }

    .video:before {
      background: url("../images/icon_video.png");
      content: "";
      width: 23px;
      height: 15px;
      display: inline-block;
      margin-right: 7px;
    }
  }
}

.image-grid-module .grid-inner {
  span.scWebEditInput {
    background: #e23e21;
    color: #fff;
    font-size: 21px;
    height: 200px;
    font-size: 21px;
    padding: 12px 30px;
  }

  h4 span.scWebEditInput {
    font-size: 33px;
    height: 100px;
    margin: 0;
    padding-top: 32px;
    width: 100%;
  }
}

.image-grid .grid-inner.scEnabledChrome h4 {
  margin: 0;
}

.generic-module table th {
  background: #79d3e5;
  font-family: "BreuerTextRegular", helvetica, arial;
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
}

.stacked .controls-nav {
  display: none;
}

@-webkit-keyframes smallFlip {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes smallFlip {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes flip {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.graph-module,
.expandable-ctn {
  .image {
    span {
      background: url("../images/icon_enlarge@2x.png") no-repeat left;
      background-size: 30px 30px;
      display: block;
      font-size: 14px;
      height: 30px;
      padding: 5px 60px 5px 37px;
      text-indent: -999em;

      &:hover {
        padding-right: 0;
        text-indent: 0;
      }

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }
  }
}

.story-module {
  .image {
    position: relative;

    span {
      background: url("../images/icon_enlarge@2x.png") no-repeat right;
      background-size: 30px 30px;
      display: block;
      font-size: 14px;
      height: 30px;
      padding: 5px 37px 5px 60px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      text-indent: -999em;

      &:hover {
        padding-left: 0;
        text-indent: 0;
      }
    }
  }
}

.article-module {
  span.enlarge {
    background: url("../images/icon_enlarge@2x.png") no-repeat right;
    background-size: 30px 30px;
    display: block;
    font-size: 14px;
    height: 30px;
    padding: 5px 37px 5px 60px;
    position: absolute;
    right: 8%;
    bottom: 107px;
    text-indent: -999em;
    cursor: pointer;

    &:hover {
      padding-left: 0;
      text-indent: 0;
    }

    @media screen and (max-width: $breakpoint-large) {
      display: none;
    }
  }
}

// Germany flip card styles

.de-de .image-grid-module > .image-grid .grid-inner .overlay h4 {
  font-size: 17px;
}
.de-de .image-grid-module > .image-grid .grid-inner .overlay p {
  font-size: 16px;
}

// Netherlands flip card styles

.nl-nl .image-grid-module > .image-grid .grid-inner .overlay h4 {
  font-size: 20px;
}
.nl-nl .image-grid-module > .image-grid .grid-inner .overlay p {
  font-size: 18px;
}

// Japan flip card styles

.ja-jp .image-grid-module > .image-grid .grid-inner .overlay p {
  font-size: 18px;
}

// Brazil flip card styles
.pt-br .image-grid-module > .image-grid .grid-inner .overlay p {
  font-size: 17px;
}

// Argentina flip card styles
.es-ar .image-grid-module > .image-grid .grid-inner .overlay h4 {
  font-size: 25px;
}
.es-ar .image-grid-module > .image-grid .grid-inner .overlay p {
  font-size: 17px;
}
