$three-up-space: 30px;

.three-up {
  @include component-whitespace;
  color: $cabot-darkest-gray;

  // &--background-image {
  //   background-image: url("../img/promo-background-left.svg");
  //   background-position: top right;
  //   background-repeat: no-repeat;
  // }

  &__title {
    font-size: 33px;
    letter-spacing: 0.34px;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
    padding-top: 40px;

    &:after {
      content: "";
      background-color: $cabot-red;
      display: block;
      height: 4px;
      margin-top: 16px;
      margin-bottom: 0;
      width: 32px;
    }

    // .has-image & {
    //   margin-top: 112px;
    // }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__column {
    background-color: $white;
    border: solid 1px $cabot-lightest-gray;
    margin: 56px auto $three-up-space auto;
    padding: 25px;
    position: relative;
    width: 100%;
    transition: margin-top 700ms;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ($bp-large-min) {
      width: calc(33.33% - #{$three-up-space});

      & + & {
        margin-left: $three-up-space;
      }
    }

    @media ($bp-medium-min) {
      &:before {
        border: solid 3px $cabot-thunderbird;
        content: "";
        position: absolute;
        // top: -10px;
        // bottom: -10px;
        // left: -10px;
        // right: -10px;
        bottom: -4px;
        left: -1px;
        opacity: 0;
        right: -4px;
        top: -2px;
        transition: opacity 700ms;
        z-index: 1;
      }

      &:hover {
        border-color: transparent;
        //using before to prevent jump from border growing
        &:before {
          opacity: 1;
        }

        margin-top: 50px;
      }
    }
  }

  &__image + &__content {
    margin-top: 25px;
  }

  &__content {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__column-title {
    font-size: 27px;
    text-align: center;
  }

  &__description {
    font-size: 16px;
    text-align: center;
    max-width: 100%;
  }

  &__image {
    align-items: flex-end;
    display: flex;
    height: 205px;
    margin-right: auto;
    margin-left: auto;
    max-width: 160px;
    // Experience Editor fix, makes sure image is clickable
    z-index: 100;
    position: relative;

    img {
      width: 100%; //needed for IE11
    }
  }

  &__link-list {
    @include zero-list();
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    // max-width: 260px;
  }

  &__list-item {
    width: 100%;
    text-align: center;

    @include at-least(4) {
      // width: calc(50% - #{$multi-promo__promo-space}/ 2);

      // @media ($bp-large-min) {
      //   width: calc(50% - $multi-promo__promo-space);
      // }
    }
  }

  //legacy links are __VERY__ opinionated
  a.three-up__link {
    color: $cabot-darkest-gray;
    font-family: $bold-font;
    font-size: 14px;

    &:after {
      content: "→";
      margin-left: 8px;
    }
  }

  &__cta-container {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: flex-end;
  }

  a.three-up__cta {
    &:hover {
      text-decoration: none;
    }
  }
}
