$container-bottom-space: 60px;
$container-content-max: $max-width;
$container-gutter: 30px;

.container {
  background-repeat: no-repeat;
  margin-bottom: $container-bottom-space;
  padding-left: $gutter;
  padding-right: $gutter;
  position: relative;

  &.is-adjacent {
    margin-bottom: 0;
  }

  &:not(&--bleed) .container {
    max-width: $container-content-max;
    padding-left: 0;
    padding-right: 0;
  }

  &.has-image.is-background-right {
    background-position: top right;
    background-size: auto;
  }

  &.has-image.is-background-left {
    background-position: top left;
    background-size: auto;
  }
}

.container__col {
  margin-bottom: $container-gutter;

  &:last-child {
    margin-bottom: 0;
  }

  @media ($bp-large-min) {
    margin-bottom: 0;
  }
}

.container--bleed {
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  & > .container__col {
    position: relative;

    .container:last-child {
      margin-bottom: 0;
    }
  }

  // &.has-image,
  // &.has-color {
  //   padding-bottom: $container-bottom-space;
  //   padding-top: $container-bottom-space;
  // }

  &.has-image {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.container--100,
.container--70-30,
.container--50-50,
.container--30-70 {
  margin-left: auto;
  margin-right: auto;
  max-width: ($container-content-max + ($gutter * 2));
}

.container--70-30,
.container--50-50,
.container--30-70 {
  @media ($bp-large-min) {
    display: grid;
    grid-gap: 30px;

    .container__col {
      min-width: 0; // hack to set a width and prevent blow out
    }
  }
}

// 2/3 - 1/3

.container--70-30 {
  @media ($bp-large-min) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

.container--50-50 {
  @media ($bp-large-min) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

// 1/3 - 2/3

.container--30-70 {
  @media ($bp-large-min) {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto; // required for IE11
  }
}

/* Colors */

.container--connect {
  background-color: $grey-200;
}
