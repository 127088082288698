.hero {
  overflow: hidden;
  position: relative;
  color: $white;

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='462' height='134' viewBox='0 0 462 134' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M430.73-114.6L297.58 116.01H31.28L430.72-114.6z' id='Rectangle-2' fill='%23000' transform='rotate(30 231 .7)'/%3E%3C/g%3E%3C/svg%3E");
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 140%;
    background-repeat: no-repeat;
    // background-size: 180%;
    background-size: cover;
    opacity: 0.4;
    background-position: 55% -10px;
    // animation: 2.5s ease-out 0s 1 heroBackdropIntro;
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: heroBackdropIntro;
    z-index: 800;
    animation-fill-mode: forwards;
  }

  &--light-theme {
    color: #000;
    &:before {
      opacity: 0.6;
      background-image: url("data:image/svg+xml,%3Csvg width='462' height='134' viewBox='0 0 462 134' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M430.73-114.6L297.58 116.01H31.28L430.72-114.6z' id='Rectangle-2' fill='%23FFF' transform='rotate(30 231 .7)'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &.hero--no-overlay:before {
    display: none;
  }
}

.hero__figure {
  margin: 0;
  position: absolute;

  picture {
    height: 100%;
  }

  img {
    width: 100%;
    display: block;
    position: absolute;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    @media ($bp-small-max) {
      min-height: 400px;
    }
  }

  video,
  .video {
    /* if object-fit is supported, we'll use that */
    width: 100%;
    display: block;
    object-fit: cover;
    margin: 0 auto;
    position: absolute;
    height: 100%;
  }
}

.has-height .hero__figure {
  width: 100%;
  height: 100%;
}

.hero__caption {
  position: relative;
  z-index: 900;
  width: 100%;
  padding: 100px 200px 120px;
  color: #fff;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: heroCaptionIntro;
  animation-fill-mode: forwards;

  .hero--light-theme & {
    color: #000;
  }

  @media ($bp-x-large-max) {
    max-width: 100%;
  }
  @media ($bp-large-max) {
    padding: 60px 100px 80px 100px;
  }
  @media ($bp-small-max) {
    padding: 40px 24px;
  }
}

.hero__title {
  color: inherit;
  font-size: 57px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 57px;
  font-family: "BreuerTextMedium", helvetica, Arial, sans-serif;
  margin-bottom: 48px;
  max-width: 600px;

  @media ($bp-large-max) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  @media ($bp-x-small-max) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }
}

.hero__subtitle {
  font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: inherit;
  text-align: left;
  margin-bottom: 48px;
  max-width: 600px;

  @media ($bp-large-max) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
  }

  @media ($bp-x-small-max) {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

a.hero__action {
  @extend .btn;
  padding: 12px 35px;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: none;
    background: $cabot-thunderbird;
  }

  @media ($bp-large-max) {
    font-size: 1rem;
  }
}

.hero__slider-arrows {
  position: absolute;
  right: 100px;
  margin-top: -100px;
  z-index: 900;

  & svg {
    width: 56px;
    height: 56px;
    margin: 0 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
.hero__slider-prev-arrow svg {
  transform: rotate(180deg);
}

@keyframes heroCaptionIntro {
  0% {
    opacity: 0;
    transform: translate(0, 10%);
  }
  80% {
    // text appears after backdrop slides in, ~2.5s
    opacity: 0;
    transform: translate(0, 10%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes heroBackdropIntro {
  0% {
    background-position: 60% -400px;
  }
  100% {
    background-position: 60% -10px;
  }
}
