$search-accent: #db371a;

.search-results-page {
	text-align: center;

	.main-content {
		width: 100%;
	}

	.shown-results {
		background: #f2f4f3;
		font-size: 19px;
		padding: 26px 0 22px 0;
	}

	.result {
		p { 
			margin: 0;
		}

		.document-type {
			color: #b2b4b3;
			font-size: 14px;
			text-transform: uppercase;
		}

		.result-link {
			font-size: 39px;
			letter-spacing: 0;
			line-height: 1.3;

			@include respond-max(700px) {
				font-size: 32px;
			}

			a, 
			a:active,
			a:visited {
				&:hover {
					text-decoration: none;
				}
			}
		}

		.result-summary {
			color: #000;
		}

		&:hover {

		}
	}

	.link-container {
		border-bottom: 1px solid #d9d9d9;
		float: left;
		padding: 42px 13% 40px 13%;
		width: 100%;

		@include respond-max(700px) {
			padding: 25px;
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&:hover {
			background: $search-accent;
			text-decoration: none;

			.document-type {
				color: #891d0a;
			}

			.result-link,
			.result-summary {
				color: #fff;
			}
		}
	}
}

.show-more {
	background: #f2f4f3;
	float: left;
	text-align: center;
	margin: 0;
	padding: 13px 0 12px 0;
	width: 100%;
	cursor: pointer;

	&:hover {
		background: $search-accent;

		a, 
		a:active,
		a:visited,
		a:hover {
			color: #fff;
			text-decoration: none;
		}
	}
}
