﻿

::-moz-selection {
	background:#1988CC;
	color:#FFFFFF;
}

::selection {
	background:#1988CC;
	color:#FFFFFF;
}


img {
	max-width: 100%;
}


body {
	color: $primary-text-color;
	background: #303030;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1.5;
	margin: 0;
	padding: 0 0 327px 0;
	position: relative;
	z-index: 0;

	@include respond-max($breakpoint-medium) {
		padding-bottom: 240px;
	}
}

html{
	background: #303030;
}

#envelope {
	background-color: #fff;
}

.wrapper{
	max-width:1024px;
	margin:0 auto;
	padding: 0 25px;

}

header.header{
	background: #ffffff;
	padding-top:5px;
	width:100%;
	position: relative;
	z-index: 999;

	.wrapper{
		position:relative;
	}

	.grid-layout{
		overflow:visible;
	}

	.nav-bar {
		background: #E03314;
		float: left;
		position: relative;
		min-height: 0px;
		max-height: 54px; //SMG - fixes a firefox alignment issue for the dropdowns
		width: 100%;


		@media screen and (max-width: $breakpoint-header) {
			max-height: none;
		}
		@media screen and (max-width: $breakpoint-medium) {
			border-bottom: 5px solid #E03314;

			.wrapper {
				padding: 0;
			}
		}

		.nav-angle {
			position: absolute;
			left: 50%;
			top: 0;
			z-index: 3;
			width: 50%;
			overflow: hidden;
			height: 54px; 

			@media screen and (max-width: $breakpoint-first) {
				display: none;
			}

			&:before {
				background: #cd2028;
				 content: "";
				display: block;
				min-height: 54px;
				width: 150%;
				-webkit-transform: skewX(-30deg);
				-moz-transform: skewX(-30deg);
				-ms-transform: skewX(-30deg);
				transform: skewX(-30deg);
				margin-left: 260px;

				@media screen and (max-width: $breakpoint-first) {
					margin-left: -280px;
				}
			}

		}
	}

}


#logo{
	float:left;
	margin: 16px 0 14px 20px ;

	img{
		width:145px;
	}
}



/* footer -------------------------------------------------------------*/


.social-bar{
	background: #f2f4f3;
	border-bottom: 1px solid rgba(0,0,0,.05);
	border-top: 1px solid rgba(0,0,0,.05);
	height:54px;
	z-index:50;
	.wrapper {
	    position: absolute;
	}	

	.logo-footer{
		display:inline-block;
		margin-top:14px;
		padding-right:20px;
		margin-right:10px;
		line-height:0;

		img {
			width: 97px;
		}
	}


	.social-links{
		float: right;
		margin: 0;
		@media screen and (max-width: 600px){
			display:none;
		}

		li{
			list-style-type: none;
			padding: 0;
			margin: 0;
			float:left;
			border-left: 1px solid rgba(0,0,0,.05);

			&:last-child{
				border-right: 1px solid rgba(0,0,0,.05);					
			}

			a{
				display:block;
				width: 53px;
				height: 53px;
				padding-top:18px;
				text-align:center;
					-webkit-transition: all ease 400ms;
				transition: all ease 400ms;



				&:hover{
					background: #ffffff;
						-webkit-transition: all ease 200ms;
					transition: all ease 200ms;
				}
			}
		}


	}
}
	


footer.footer{
	background: #303030;
	color:#e9e9e9;
	font-size:13px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: -1;
	padding-top: 57px;

	.nav-footer{
		
		border-right: 1px solid #4f5051;
		padding-left: 40px;

		&:first-child {
			padding-left: 20px;
		}

		&:last-child {
			border: none;
		}

		@include respond-max($breakpoint-medium) {
			display: none;
		}

		/*@media screen and (max-width:$breakpoint-medium){
			h4:after{
				color:rgba(255,255,255,.4);
				content: "+";
				float:right;
				font-size:20px;
				margin-right:15px;
				position: relative;
					top:-6px;
			}
			
			ul{
				display:none;
		
			}
		
			&.open{

				h4:after{
					color:rgba(255,255,255,.4);
					content: "-";
				}

				ul{
					display:block;
					margin-bottom:15px;
				}
			}

			&.search h4{
				display:none;
			}
		} */

		h4{
			margin-bottom:17px;
			margin-top:0;
			text-transform:uppercase;
			font-family: "BreuerTextRegular", helvetica, arial;
			font-weight:300;
			color: #c2c2c2;
		}

		ul {
			list-style-type: none;
			margin:0;
			padding:0;

			li {
				margin:0;
				margin-bottom: 17px;
				padding:0;
	
				&:last-child {
					margin-bottom: 0;
				}

				a {
					color:#64cade;

					&:hover {
						color: #ffffff;
						text-decoration: underline;
					}
				}
			}
		}
	}

	#subfooter {
		padding-top: 70px;
		padding-bottom: 25px;
		color: #a0a0a0;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

		}

		li {
			margin: 0;
			padding: 0;
			display: inline-block;
			border-left: 1px solid #4f5051;
			padding: 0 20px;
			position: relative;

			&:first-child {
				padding-left: 0;
				border: none;
			}
		}

		.subfooter-nav {
			color: #a0a0a0;
			padding-bottom:10px;

			a {
				color:#64cade;

				&:hover {
					color: #ffffff;
					text-decoration: underline;
				}
			}
			@include respond-max($breakpoint-medium) {
				li {
					border-left: none;
					display: block;
					padding: 10px 0;
				}
			}
		}

		@include respond-max($breakpoint-medium) {
			padding-top: 20px;
		}
	}

	.acc-logo {
		position: absolute;
		right: -24px;
		bottom: 4px;
	}

	@include respond-max($breakpoint-medium) {
		padding-top: 0;
	}
}

.search-bar {
	height: 0px;
	background: #ecefee url(../../images/pattern_tile4.png);
	position: relative;
	transition: all linear 300ms;
	overflow: hidden;
	width: 100%;

	&.open {
		height: 81px;
	}

	.search-angle {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 3;
		width: 50%;
		overflow: hidden;
		height: 81px;
		z-index: 1;

		@media screen and (max-width: $breakpoint-first) {
			left: 100%;
		}

		@media screen and (max-width: $breakpoint-header) {
			display: none;
		}

		&:before {
			background: #e1e1e1 url(../../images/pattern_tile4.png);
			content: "";
			display: block;
			min-height: 81px;
			width: 150%;
				-webkit-transform: skewX(-30deg);
				-moz-transform: skewX(-30deg);
				-ms-transform: skewX(-30deg);
			transform: skewX(-30deg);
			margin-left: 221px;

			@media screen and (max-width: $breakpoint-first) {
				margin-left: -280px;
			}
		}
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 2;
		
		input {
			border: 0;
			background: none;
			font-size: 30px;
			padding-top: 21px;
			width: 66%;
			float: left;

			&:focus {
				outline: none;
			}

			&::selection {
				background: rgba(210, 210, 210, 0.7);
			}

			@include respond-max(540px) {
				font-size: 18px;
				padding-top: 27px;
				width: 84%;
			}
		}

		button {
			border: 0;
			background: url(../../images/search-arrow.png) center no-repeat;
			height: 35px;
			width: 35px;
			margin-top: 21px;

			@include respond-max(1024px) {
				position: absolute;
				right: 0;
			}

			@include respond-max(540px) {
				position: absolute;
				right: -5px;
			}

		}
	}

}


header.header {
	-webkit-font-smoothing: subpixel-antialiased !important;
}