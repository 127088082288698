﻿.nav-primary {
	@extend %clearfix;
	padding-left:2px;
	position: relative;
	width: 75%;
	z-index: 5;

	@media screen and (max-width: $breakpoint-large){
		padding-left: 0px;
	}
	
	@media screen and (max-width: $breakpoint-large){
		width: 75%;
	}

	@include respond-max (810px) {
		width: 100%;
	}

	> ul {
		list-style: none;
		margin:0;
		margin-top:17px;
		padding:0;

		@media screen and (max-width: $breakpoint-medium){
			display:none;
			margin-top: 0px;
		}


		li.top-level {
			float: left;
			font-size: 15px;
			padding:0;
			padding-right:32px;

			@include respond-max(900px) {
				padding-right: 15px;
			}

			&.open {

				.nav-menu {
					opacity: 1;
					visibility: visible;
					display: block;
				}

				span.expand:after {
					@media screen and (max-width: $breakpoint-medium){
						content: "-";
					}
				}
			}




			@media screen and (max-width: $breakpoint-medium){
				float: none;
				font-size: 18px;
				padding-right: 0;
				border-bottom: 1px solid rgba(255, 255, 255, .2);
				position: relative;

				span.expand {
					position: absolute; 
					right: 0px;
					top: 0px;
					bottom: 0px;
					width: 68px;
					background: #cd2028;
					color: white;
					font-weight: bold;
					font-family: verdana, helvetica, arial;
					font-size: 18px;
					text-align: center;
					padding-top: 10px;
					display: block;

					&:after {
						content: "+";

					}
				}




				}

				&:after {
					

			}

			.nav-title {
				padding-right: 20px;
			}

			> a {
				color: #ffffff;
				display: inline-block;
				padding: 0px 0px 2px 0px;
				border-bottom: 1px solid rgba(255, 255, 255, 0);
				margin-bottom: 12px;

				@media screen and (max-width: $breakpoint-medium){
					margin-bottom: 8px;
					margin-top: 13px;
					margin-left: 25px;
				}


			
				&:hover{
					border-bottom: 1px solid rgba(255, 255, 255, .3);
					text-decoration: none;
					// color: #f26e30;
					
				}
			}

			&:hover {
				
				-moz-transition-delay: 0s;
				transition-delay: 0s;

				> a{
					border-bottom: 1px solid rgba(255, 255, 255, 0);
				}

				.nav-title {
					background-position: 100% -33%;
					color: #FFFFFF;
					transition-delay: 0s;
				}

				@media screen and (min-width: $breakpoint-medium) {
					.nav-menu {
						visibility: visible; 
						opacity: 1;
						display:block; 
						-moz-transition-delay: 0s;
						transition-delay: 0s;
						z-index: 200;

						a {
							text-decoration: none;
						}
					}				
				}
			}

			&.active {
				> a{
					border-bottom: 1px solid #ffffff;
					// color: #f26e30;
					padding: 0px 0px 2px 0px;

					@media screen and (max-width: $breakpoint-medium){
						padding: 0px 0px 0px 0px;
						margin-bottom: 7px;
					}
				}
			}

			.nav-menu {
				font-size: 16px;
				background:$secondary-background url(../../images/menu-angle.png) bottom right no-repeat;
				margin: 0;
				opacity: 0;
				padding: 30px 30px 10px 30px;
				position: absolute;
					top: 54px;
				margin-left: -30px;
				-moz-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				-webkit-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				-o-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				visibility: hidden;
				width: 220px;
				z-index: 999;
				line-height: 1.3em;


				@media screen and (max-width: $breakpoint-medium){
					position: relative;
					top: 0;
					margin-left: 0;
					width: 100%;
					background-image: none;
					display: none;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					background: url(../../images/pattern_tile3.png) top left repeat;
					z-index: -1;
					opacity: .05;
				}

				.active a {
					color: #ce1818;

					&:hover {
						text-decoration: none;
						color: #ce1818;
						border-bottom: 1px solid #ce1818;
					}
				}

				ul {
					margin: 0;
					padding: 0;
					list-style-type: none;

					li {
						margin: 0;
						padding: 0 0 20px 0;
						&.overview {
							@media screen and (min-width: $breakpoint-medium) {
								display: none;
							}
						}
					}

				}
			

				a {
					background-color:none;
					color: $primary-text-color;
					font-weight: normal;
					padding:0;
					-moz-transition: background-color .25s linear;
					-webkit-transition: background-color .25s linear;
					-o-transition: background-color .25s linear;
					transition: background-color .25s linear;
					border-bottom: 1px solid rgba(255,255,255, .0);

					&:hover{
						color: $primary-text-color;
						border: none;
						text-decoration: none;
						border-bottom: 1px solid $primary-text-color;
					}
				}
				
				.nav-tertiary{
					padding:0;

					a{
						font-size:14px;
					}
				}
			}

		}
	}

	&.open{

		@media screen and (max-width: $breakpoint-medium){
			> ul{
				background: $secondary-background;
				border-top: 4px solid $primary-accent-color;
				padding-top:20px;
				position:absolute;
					left:0;
					top:52px;
				width:100%;
				z-index: 999;
				padding-left:25px;

				> li {
					&:before{
						color:rgba(0,0,0,.4);
						content: "+";
						float:right;
						font-size:20px;
						margin-right:25px;
					}

					&.open:before{
						content: "-"
					}
				}
			}
		
			ul{
				display:block;

				li{
					display:block;
					float:none;
					width:100%;

					a{
						color: $primary-text-color;
					}
	
					.nav-menu {
						border-top:none;
							-webkit-box-shadow:none;
		        		box-shadow:none;
						visibility: hidden;
						opacity: 0;
						display:none;
						padding:0;
						position: relative;
							top:0;
							left:0;
							right:0;
							bottom:0;
						z-index: 200;

						.wrapper{
							padding:0;
							width:auto;
						}

						.grid-layout{
							margin:0;
						}

						.col3{
							padding:0;
						}
					}

					&.open{
						.nav-menu{
							opacity:1;
							visibility:visible;
							display:block;
						}
					}
				}
			}
		}
	}
}

.nav-primary > ul li.top-level.activeTrail:last-of-type {
	padding-right: 0;
}


.nav-right {
	text-align:right;
	font-size:13px;
	margin-right:10px;
	padding-top: 21px;
	position:absolute;
		right:0;
		top:0;


	@media screen and (max-width: $breakpoint-large){
		position:absolute;
		right:5px;
	}

	@media screen and (max-width:$breakpoint-header){
		display:none;
		width: 100%;
		margin: 0;
		padding: 20px;
		padding-bottom: 0;
		background: rgba(255, 255, 255, .9);
		right: 0;
		top: 130px;
	}

	@media screen and (max-width:$breakpoint-medium){
		top: 250px;
	}

	ul.utility-nav{
		padding: 0;
		margin: 15px 0px 0 0;
		list-style-type: none;
		float: left;
		position: relative;
		top: -11px;
		left: -13px;

		li{
			float: left;
			padding-left:7px;
			padding-right:10px;
			letter-spacing: .05em;
			margin-left: 10px;
			border-right: 1px solid #dfe0df;

			&:last-child{
				padding-right:0px;
				border: none;

				a {
					margin: 0px 0px 0px 4px;

					@media screen and (max-width:$breakpoint-medium){
						margin: 0px 5px 0px 0px;
					}
				}
			}

			a{
				color: #000000;
				margin: 0px 5px 0px 4px;
				padding-bottom: 2px;

				&:hover{
					color: #db371a;
					text-decoration:none;
					border-bottom: 1px solid red;
				}
			}

		}
	}

}

.nav-primary {
	.search {
		display: none;
	}

	ul.utility-nav {
		list-style-type: none;
		margin-top: 0;
		padding-left: 0px;
		padding-top: 13px;
		padding-bottom: 13px;
		background: #5e5f61;



		li{
			padding-right:10px;
			letter-spacing: .05em;
			margin-left: 25px;
			padding-bottom: 5px;


			a{
				color: #fff;
				margin: 0px 5px 0px 0px;
				padding-bottom: 2px;
				font-size: 13px;

				&:hover{
					color: #fff;
					text-decoration:none;
					border-bottom: 1px solid rgba(255, 255, 255, .3);
				}
			}

		}
	}
}




/* sublevel navigation ------------------------------------------------------- */

.nav-secondary{
	margin-top:96px;
	height:100%;
	letter-spacing: .015em;
	opacity:1;	
	-webkit-transition: opacity 100ms;
	transition: opacity 100ms;


	ul{
		list-style-type:none;
		margin:0;
		padding:0;
	}

	.top-level{
		border-bottom: 1px solid rgba(0, 0, 0, .12);
		font-size:15px;
		line-height:19px;
		padding:0px;

		a{
			color: $primary-text-color;
			display:block;
			padding:12px 10px;


			&:hover{
				color:#e03314;
				text-decoration:none;
			}
		}

		&.nav-secondary-title{   
			background: url("../../images/secondary_nav_header.png") #E03314 right top no-repeat;
			border-bottom:none;
			position:relative;
			margin-bottom:15px;
			font-family: 'BreuerTextLight', helvetica, arial;
			letter-spacing:.03em;
			
			a{
				color:#ffffff;

				&:hover{
					color:#ffffff;
				}
			}

			&:before{
				content: "";					
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid #e23e21;
				height: 0;
				position:absolute; 
					bottom:-5px;
					left:5px;
				width: 0; 
					
			}
		}
	}

	.nav-menu{
		font-size:14px;
		margin:2px 0;

		li{
			a{
				padding: 0 0 14px 16px;

				&.active {
					color: $primary-accent-color;
				}
			}
		}
	}

	li.active {
		
		> a{
			color: #e03314;
		}

	}

	li.active .active a{
		color:$primary-accent-color;
	}

	//@media screen and (max-width:$breakpoint-first){
	@media screen and (max-width: 768px) {
		margin-top:30px;
		width:100%;
		display: none;

		// li.top-level.nav-secondary-title {
		// 	&:before{
		// 		content: "";
		// 		border-left: 7px solid transparent;
		// 		border-right: 7px solid transparent;
		// 		border-top: 7px solid #ffffff;
		// 		height: 0;
		// 		position: absolute;
		// 			bottom: 16px;
		// 			left: auto;
		// 			right: 17px;
		// 		width: 0;
		// 			-webkit-transform: rotate(180deg);
		// 		transform: rotate(180deg);
		// 			-webkit-transition: all ease 300ms;
		// 		transition: all ease 300ms;
		// 	}

		// 	a{
		// 		display:inline-block;
		// 		width:auto;
		// 	}
		// }

		// &.closed{
		// 	li{
		// 		display:none;
		// 	}

		// 	li.top-level.nav-secondary-title{
		// 		display:block;

		// 		&:before{
		// 				-webkit-transform: rotate(0deg);
		// 			transform: rotate(0deg);
		// 				-webkit-transition: all ease 300ms;
		// 			transition: all ease 300ms;
		// 		}
		// 	}
		// }
	}

}

@media screen and (min-width:$breakpoint-first){
	.nav-secondary.fixed{
		.main-side{
			position: fixed;
			top: 50px;
			left:auto;
			padding-right:20px;
			width:234px;
			opacity:1;	
				-webkit-transition: opacity 100ms;
			transition: opacity 100ms;

			.container--30-70 & {
				position: static;
			}
		}
	}


	.hidden{
		opacity:0;	
			-webkit-transition: opacity 100ms;
		transition: opacity 100ms;
		visibility: hidden;
	}

}


.nav-secondary.solutions-nav {

	.nav-secondary-title:before {
		content: "";					
		border: none;	
	}

	.nav-secondary-title{
		margin-bottom: 0px;
	}
	.nav-menu{
		font-size:14px;
		margin:2px 0;

		li{

			a{
				padding: 0 0 14px 10px;
				font-size: 15px;
				line-height: 19px;

			}
		}
	}
	.nav-tertiary-title {
		position: relative;
		border-bottom: 1px solid #e23e21;
		font-family: 'BreuerTextLight', helvetica, arial;
		margin-bottom: 16px;
		
		a {
			color: #db371a;
		}

		&:before{
			content: "";					
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid #e23e21;
			height: 0;
			position:absolute; 
				bottom: -7px;
				left: 10px;
			width: 0; 	
		}
	}

	.category-title {
		font-family: 'BreuerTextRegular', helvetica, arial;
		text-transform: uppercase;
	}

	.nav-menu li:last-child {
		margin-bottom: 10px;
	}
	
	.additional-links{
		padding-top: 20px;
	}

	li:last-child {
		border: none;
	}


}





.breadcrumb {
	margin-top: 25px;
	margin-bottom: 3px;
	font-family: "BreuerTextRegular", helvetica, arial;

	@media screen and (min-width: $breakpoint-first){
		display: none;
	}
}

.breadcrumb-parent {
	color: $secondary-text-color;
	font-size: 13px;
	padding-left: 10px;
	
	a {
		font-size: 16px;
		padding-left: 3px;
	}
}




.mobile-subnav {
	//display: block !important;
	background: #fff;
	display: none;
	float: left;
	position: relative;
	width: 100%;
	z-index: 20;

	@include respond-max(768px) {
		display: block;
	}


	> .nav-menu {
		display: none;
	}

	> .category-title {
		display: none;
	}

	ul.main-side {
		display: none;
		float: left;
		margin-bottom: 20px;
		width: 100%;

		.nav-secondary-title {
			display: none;
		}

		.nav-tertiary-title {
			position: relative;
			font-family: 'BreuerTextLight', helvetica, arial;
			margin-bottom: 16px;
		
			a {
				border-bottom: 1px solid #e23e21;
				color: #db371a;
			}

			&:before{
				content: "";					
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-top: 7px solid #e23e21;
				height: 0;
				position:absolute; 
					bottom: -7px;
					left: 36px;
				width: 0;
			}
		}
	}

	.top-level.nav-secondary-title {
		float: left;
		list-style: none;
		margin-bottom: 9px;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: 999;
	}

	.top-level.nav-secondary-title a {
		background: #f2f4f3;
		border-bottom: 1px solid #e6e8e7;
		color: #db371a;
		float: left;
		font-size: 20px;
		padding: 13px 0 13px 25px;
		width: 100%;
	}

	.top-level.nav-secondary-title .mobile-nav-control {
			background-color: #e6e8e7;
			background: #e6e8e7 url('../images/mobile-down.png') no-repeat center;
			background-size: 13px 8px;
			content: "";
			display: block;
			width: 73px;
			height: 56px;
			position: absolute;
				right: 0;
				top: 0;
		

		&.opened {
			transform: rotate(180deg);
		}
	}

	.top-level.activeTrail > a {
		border-bottom: 0;
		padding-bottom: 9px;
	}
	
	ul {
		font-size: 16px;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			background-image: none;
			margin: 0;
			padding: 0 25px;

			a{
				padding: 11px 0 10px;
				color: #000;
				border-bottom: 1px solid #e0e0e0;
				display: block;
				font-size: 15px;

				&.active {
					color: #db371a;
				}
			}

			.nav-menu {
				border-bottom: 1px solid #e0e0e0;
				padding-bottom: 10px;

				li {

				}

				a {
					border-bottom: 0;
					font-size: 14px;
					padding: 7px 0;
				}
			}
		}
	}

}




.global-tools {

	padding: 0;
	margin: 0;
	list-style-type: none;
	float: right;
	width: 300px;
	display: block;
	position: absolute;
	top: 17px;
	right: 25px;

	li {
		display: inline-block;
		margin-left: 24px;
		float: right;

		> a {
			color: #ffffff !important;
			display: inline-block;
			float: right;
			font-size: 14px;
			z-index: 4;
			position: relative;
			padding-left: 7px;
			margin-top: -6px;

			&:visited {
				color: #ffffff !important;
			}

			@media screen and (max-width:$breakpoint-header){
				display:none;
			}

			&:hover {
				color: #ffffff;
				text-decoration: none !important;
				border: none;				
			}

			.dropdown-globe {
				position: relative;
				left: -3px;
				top: 6px;
				width: 24px;
			}
		}
	}

	// Language Dropdown

	.language-top {
		.nav-menu {
			font-size: 16px;
			background: $secondary-background;
			margin: 0;
			opacity: 0;
			//padding: 25px 30px 10px 30px;
			padding: 0;
			position: absolute;
			top: 37px;
			right: 0;
			margin-left: -20px;
			-moz-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
			-webkit-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
			-o-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
			transition: visibility 0s linear 0.25s,opacity 0.25s linear;
			visibility: hidden;
			width: 206px;
			line-height: 1.3em;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				background: url(../../images/pattern_tile3.png) top left repeat;
				z-index: -1;
				opacity: .05;
			}

			> ul {
				float: left;
				margin: 0;
				padding: 25px 30px 10px 30px;

				> li {
					clear: both;
					float: left;
					margin: 0;
					padding: 0 0 15px 0;

					> a {
						//color: #000 !important;
						font-size: 16px;
						padding-left: 0;
					}

					&:hover {
						//padding-bottom: 19px;

						a {
							//border-bottom: 1px solid #000;
						}
					}

					&.nav-region-title {
						font-family: 'BreuerTextLight', helvetica, arial;
						font-weight: bold;
						padding-bottom: 15px;
						text-transform: uppercase;
					}

					&.nav-country {
						&:hover {
							border-bottom: 0;
							padding-bottom: 15px;
						}
					}

					&.nav-country a {
						color: #000 !important;
						font-family: 'BreuerTextRegular', helvetica, arial;
						font-size: 16px;

						&:hover {
							color: #e03314 !important;
						}
					}
				}

			}
			.worldwide-loc-link {
				background: url('../images/icon-compass-2x.png') no-repeat 26px 14px;
				background-color: #e3e3e3;
				background-size: 32px 32px;
				color: #e03314;
				float: left;
				font-size: 15px;
				font-weight: bold;
				padding: 20px 0 20px 65px;
				text-transform: uppercase;
				width: 100%;
			}
		}

		&:hover {
			-moz-transition-delay: 0s;
			transition-delay: 0s;

			> a{
				//border-bottom: 1px solid rgba(255, 255, 255, .3);
			}

			.nav-title {
				background-position: 100% -33%;
				color: #FFFFFF;
				transition-delay: 0s;
			}

			.nav-menu {
				visibility: visible; 
				opacity: 1;
				display:block; 
				-moz-transition-delay: 0s;
				transition-delay: 0s;
				z-index: 200;

				a {
					text-decoration: none;
				}
			}
		}

	}
}


.icon-nav{
	float:right;
	position:absolute;
	right: 25px;
	top: 31px;
	display: none;
	
	a{
		padding-left: 15px;

		@media screen and (max-width: $breakpoint-small){
			padding-left: 4px;
		}

		img {
			width: 24px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	@media screen and (max-width: $breakpoint-header) {
		display:inline-block;
		right: 20px;
	}
}

span.products-plus {
	font-weight: bold;
	color: #e44522;
	position: relative;
	bottom: 5px;
	right: -3px;
	font-size: 17px;
	font-weight: normal;
	bottom: 4px;
	right: -2px;
	font-size: 16px;
	font-family: arial;
}

.mobile-language-menu,
.mobile-tertiary-menu {
	background-color: #ccc;

	ul {
	    list-style: none;
	    margin: 0;
	    padding: 10px 30px;
		display: none;
	
	    li {
	        list-style-type: none;
	        margin-bottom: 10px;
	
	        a {
	            color: #666;
	            font-size: 20px;                
	        }
	    }
	}
}

.mainNav.openNav {
	display: block;
}

.utility-nav.openNav {
	display: block;
}

.mobile-language-menu ul.openMenu {
	display: block;

	@include respond-min(770px) {
		display: none;
	}

	@media screen and (min-width: 770px) {
		display: none;
	}
}

.mobile-language-menu {
	@include respond-min(770px) {
		display: none;
	}
}

.mobile-language-menu {
	@media screen and (min-width: 770px) {
		display: none;
	}
}
