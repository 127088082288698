.region-page {
  .country-list-wrapper,
  .country-selector-wrapper {
    padding: 52px 0 70px 0;
    background-color: #fff;
    width: 100%;

    .divider {
      @extend %clearfix;
      border-bottom: 1px solid #ccc;
      margin-bottom: 25px;
    }
  }

  a.country-bubble {
    background: #e9e9e9;
    border-radius: 20px;
    color: #000;
    display: block;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 1.5%;
    padding: 8px 20px;

    @media screen and (max-width: $breakpoint-small) {
      padding: 2px 8px;
      margin-bottom: 5px;
      font-size: 0.8em;
    }

    &.selected,
    &:hover {
      background: #64cade;
      color: #000;
      text-decoration: none;
    }
  }

  .country-listing {
    clear: both;
    list-style: none;
    padding-left: 0;
    width: 100%;
    margin-top: 50px;
    padding-top: 50px;
    margin-bottom: -90px;

    li {
      @extend %clearfix;
      background-image: none;
      display: block;
      clear: both;
      padding-bottom: 40px;
      padding-left: 0;
      width: 100%;

      span.anchor {
        display: block;
        margin-top: -90px;
        height: 90px;

        @include respond-max(700px) {
          margin-top: 0;
          height: auto;
        }
      }
    }

    .office-city {
      background: #f0f0f0;
      font-weight: bold;
      padding: 12px 0 10px;
      text-align: center;
      text-transform: uppercase;
    }

    .office-name {
      background: url("../../images/country-dotted-line.png") repeat-x bottom;
      font-size: 19px;
      padding-bottom: 7px;
    }

    .contact-col {
      float: left;
      font-size: 14px;
      margin-right: 3%;
      width: 30%;

      @media screen and (max-width: $breakpoint-medium) {
        float: none;
        width: 100%;
      }

      p {
        margin: 5px 0;
      }

      .office-phone,
      .office-fax {
        display: block;
        width: 100%;

        strong {
          margin-right: 5px;
          text-transform: uppercase;
        }
      }
    }

    // .anchor {
    // 	display: block;
    // 	position: relative;
    // 	top: -180px;
    // 	visibility: hidden;
    // }
  }

  .country-list-wrapper {
    display: none;

    select {
      margin-bottom: 15px;
    }

    @include respond-max($breakpoint-medium) {
      display: block;
    }
  }

  .country-selector-wrapper a {
    @include respond-max($breakpoint-medium) {
      display: none;
    }
  }

  .sticky .content-contain {
    width: 39%;

    @include respond-max(1720px) {
      width: 44%;
    }

    @include respond-max(1530px) {
      width: 50%;
    }

    @include respond-max(1355px) {
      width: 56%;
    }

    @include respond-max(1210px) {
      width: 63%;
    }

    @include respond-max(1077px) {
      width: 71%;
    }

    @include respond-max(1023px) {
      width: 100%;
    }

    @include respond-max(950px) {
      width: 87%;
    }

    @include respond-max(900px) {
      width: 95%;
    }
  }

  .country-selector-wrapper {
    background-color: #ffffff;
    background: -moz-linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -o-linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -ms-linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#ffffff  ', EndColorStr='#ffffff  ');
    float: left;

    .divider {
      display: none;
    }

    &.sticky {
      padding-bottom: 70px;

      @include respond-max(700px) {
        padding: 20px 0 40px 0;
      }
    }
  }
}

.location-office-type-icon span {
  margin-left: 25px;
  line-height: 35px;
}

.sticky {
  .content-contain {
    @include respond-max(700px) {
      height: 40px;
    }
  }

  .country-list-wrapper {
    @include respond-max(700px) {
      padding: 10px 0;
    }
  }
}

.region-page {
  .country-selector-wrapper {
    @include respond-max(700px) {
      padding: 0;
      margin-bottom: -86px;
    }
  }

  .country-list-wrapper {
    @include respond-max(700px) {
      padding: 20px 0;
    }
  }
}
