$multi-promo__promo-space: 30px;

.multi-promo {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$multi-promo__promo-space};
}

.multi-promo__promo {
  overflow: hidden;
  margin-bottom: $multi-promo__promo-space;
  margin-left: $multi-promo__promo-space;
  position: relative;
  width: calc(50% - #{$multi-promo__promo-space});

  @include exactly(1) {
    width: 100%;
  }
  @include exactly(3) {
    width: calc(33.333% - #{$multi-promo__promo-space});
  }
  @include exactly(4) {
    width: calc(33.333% - #{$multi-promo__promo-space});
    &:nth-child(4) {
      width: 100%;
    }
  }
  @include exactly(5) {
    width: calc(33.333% - #{$multi-promo__promo-space});
    &:nth-child(4),
    &:nth-child(5) {
      width: calc(50% - #{$multi-promo__promo-space});
    }
  }

  @media ($bp-large-max) {
    @include exactly(1) {
      width: 100%;
    }
    @include exactly(3) {
      width: calc(50% - #{$multi-promo__promo-space});
      &:nth-child(3) {
        width: 100%;
      }
    }
    @include exactly(4) {
      width: calc(50% - #{$multi-promo__promo-space});
      &:nth-child(4) {
        width: calc(50% - #{$multi-promo__promo-space});
      }
    }
    @include exactly(5) {
      width: calc(50% - #{$multi-promo__promo-space});
      &:nth-child(5) {
        width: 100%;
      }
    }
  }
  @media ($bp-medium-max) {
    width: 100% !important;
  }

  // @media ($bp-medium-min) {
  //   width: calc(50% - #{$multi-promo__promo-space});
  // }
}

.multi-promo__image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.multi-promo__title {
  font-size: 27px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.multi-promo__copy {
  background-color: rgba(206, 24, 30, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 50px 40px;
  height: 100%;

  &:before {
    content: "";
    width: calc(100% + 100px);
    height: 60px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg  viewBox='0 0 1140 182' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1140 .722V182H0v-.001L1140 .722z' fill='%23CE181E' fill-rule='evenodd' fill-opacity='.8' /%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    margin-left: -50px;
    top: -60px;
    position: absolute;
  }

  .multi-promo--dark & {
    background-color: rgba(17, 17, 17, 0.6);

    &:before {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg  viewBox='0 0 1140 182' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1140 .722V182H0v-.001L1140 .722z' fill='%23111111' fill-rule='evenodd' fill-opacity='.6' /%3e%3c/svg%3e");
    }
  }
}

.multi-promo__content {
  z-index: 200;
  position: relative;
  transform: translate3d(0, calc(100% - 80px), 0);
  -ms-transform: translate3d(0, 150px, 0); // ie11 doesn't like nested calc()
  transition: all 450ms cubic-bezier(0.32, 0.59, 0.19, 0.96);
  height: 100%;

  .multi-promo__promo:hover & {
    transform: translate3d(0, 0px, 0);
  }

  @media ($bp-medium-max) {
    transform: translate3d(0, 0px, 0);
  }
}

.multi-promo__description,
.multi-promo__title,
.multi-promo__cta {
  opacity: 0;
  transition: all 450ms cubic-bezier(0.32, 0.59, 0.19, 0.96);

  .multi-promo__promo:hover & {
    transform: translate3d(0, 10px, 0);
    opacity: 1;
  }

  @media ($bp-medium-max) {
    transform: translate3d(0, 10px, 0);
    opacity: 1;
  }
}
.multi-promo__title {
  opacity: 1;
}

.multi-promo__description {
  font-size: 16px;
  letter-spacing: 0.17px;
}

.multi-promo__cta {
  align-self: flex-start;

  .multi-promo--dark &:not(:hover) {
    background-color: $cabot-lightest-gray;
  }
}

// 30-column and 50-column
.container--70-30 .container__col:last-child,
.container--30-70 .container__col:first-child,
.container--50-50 .container__col {
  .multi-promo__promo {
    width: 100%;
  }
}
