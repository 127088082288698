.tabs {
  -webkit-backface-visibility: hidden; /* Chrome and Safari */
  -moz-backface-visibility: hidden; /* Firefox */
}

#tabs {
  //min-height: 1000px;
}

.solutions-landing h1:before {
  @media screen and (min-width: $breakpoint-small) {
    border: none !important;
  }
}
span.products-plus {
  font-weight: bold;
  color: #e44522;
  position: relative;
  bottom: 5px;
  right: -3px;
  font-size: 17px;
  font-weight: normal;
  bottom: 4px;
  margin-left: -7px;
  font-size: 16px;
  font-family: arial;
}

.products-plus-tab .wrapper {
  color: #000;
  transition: color linear 100ms;
}

.solutions-header {
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  background-color: #000;
  height: 360px;
  width: 100%;
  color: #ffffff;
  position: relative;

  @media screen and (max-width: 1280px) {
    background-size: auto;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }

  .wrapper {
    position: relative;

    &.light-text {
      color: #000000;
    }
  }

  .applications-header {
    display: none;
    position: absolute;
    top: 0;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    height: 360px;
    width: 100%;

    @media screen and (max-width: 1280px) {
      background-size: auto;
    }

    @media screen and (max-width: $breakpoint-small) {
      height: auto;
      bottom: 0;
    }

    &.page-editor {
      background-attachment: scroll;
    }
  }

  .products-plus-header {
    display: none;
    position: absolute;
    top: 0;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    height: 360px;
    width: 100%;

    @media screen and (max-width: 1280px) {
      background-size: auto;
    }

    @media screen and (max-width: $breakpoint-small) {
      height: auto;
      bottom: 0;
    }

    &.page-editor {
      background-attachment: scroll;
    }
  }

  .product-index-header {
    display: none;
    position: absolute;
    top: 0;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    height: 360px;
    width: 100%;

    @media screen and (max-width: 1280px) {
      background-size: auto;
    }

    @media screen and (max-width: $breakpoint-small) {
      height: auto;
      bottom: 0;
    }

    &.page-editor {
      background-attachment: scroll;
    }
  }

  h1 {
    font-family: "BreuerTextLight", helvetica, arial;
    font-size: 27px;
    font-weight: 300;
    font-weight: normal;
    line-height: 1.35em;
    margin: 0;
    max-width: 460px;
    padding-top: 77px;
    text-transform: none;

    @media screen and (max-width: $breakpoint-small) {
      padding-top: 35px;
      font-size: 23px;
      padding-bottom: 35px;
    }
  }
}

.m-nav {
  display: none;

  @media screen and (max-width: $breakpoint-small) {
    //display: block;
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }

  ul {
    padding: 0;
    margin: 0;

    li a {
      padding-left: 0 !important;
    }
  }

  .active {
    color: #e66a2f;
  }
}

.landing-page.tab-nav {
  background: #f2f4f3;
  border-bottom: 1px solid #e9e9e9;
  border-top: 0px solid #e03314;
  margin-top: -55px;
  -webkit-transition: background linear 100ms;
  transition: background linear 100ms;
  z-index: 500;
  position: relative;
  height: 55px;

  @media screen and (max-width: $breakpoint-medium) {
    height: 49px;
  }
  @media screen and (max-width: $breakpoint-header) {
    margin-top: -40px;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -12px;
    height: 59px;

    > .wrapper {
      padding: 0;
    }
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }

  &.fixed {
    border-bottom: 30px solid #ffffff;
    border-top: 3px solid #e03314;
    margin-top: 0;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 50;
    height: 88px;
    transform: 0;
    transform-style: preserve-3d;
    transition: all linear 100ms;
    transition: background linear 100ms;

    @media screen and (max-width: $breakpoint-medium) {
      height: 82px;
    }

    @media screen and (max-width: $breakpoint-small) {
      position: static;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0px;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 -2px;

      &:last-child a {
        border-right: 1px solid #e9e9e9;
      }

      a {
        color: #000000;
        border-left: 1px solid #e9e9e9;
        font-size: 18px;
        font-family: "BreuerTextLight", helvetica, arial;
        letter-spacing: 0.01em;
        display: block;
        padding: 15px 29px 13px 29px;
        transition: background linear 100ms;
        position: relative;

        @media screen and (max-width: $breakpoint-medium) {
          font-size: 14px;
          padding: 15px 20px 13px 20px;
        }

        @media screen and (max-width: 540px) {
          padding: 15px 13px 13px 13px;
        }

        @include respond-max(400px) {
          padding: 13px 11px;
        }

        &.active {
          background: #ffffff;
          color: #e03314;
          padding-top: 14px;
          margin-top: -1px;

          &:hover {
            color: #e03314 !important;
            background: #ffffff;
            text-decoration: none;
            color: $primary-accent-color;
            border-bottom: 0;
            padding-bottom: 13px;
          }

          &:after {
            content: "";
            position: absolute;
            height: 1px;
            bottom: 10px;
            right: 29px;
            left: 29px;
            background: #e03314;

            @media screen and (max-width: $breakpoint-medium) {
              right: 20px;
              left: 20px;
            }

            @media screen and (max-width: $breakpoint-medium) {
              right: 13px;
              left: 13px;
            }
          }
        }

        &:hover {
          text-decoration: none;
          background: #ffffff;
          border-bottom: 1px solid #e9e9e9;
          padding-bottom: 12px;
        }
      }
    }
  }
}

#tabs {
  padding-bottom: 100px;

  * {
    /*-webkit-transform: translateZ(0) scale(1.0, 1.0);*/
    -moz-transform: translateZ(0) scale(1, 1);
  }

  .tab {
    @extend .wrapper;
    position: relative;

    &:after {
      display: table;
      content: "";
      clear: both;
    }
  }
}

#applications.tab,
#products-plus.tab {
  margin-top: 80px;

  @media screen and(max-width: $breakpoint-small) {
    margin-top: 30px;
  }

  .item {
    color: $primary-text-color;
    display: block;
    float: left;
    min-height: 365px;
    position: relative;
    width: 33.3333%;
    -webkit-transition: all linear 100ms;
    transition: all linear 100ms;

    @media screen and(max-width: $breakpoint-small) {
      min-height: 0;
      padding-bottom: 40px;
    }

    img {
      width: 100%;
      transform: scale(1);
      transition: all cubic-bezier(0.37, 0.36, 0.36, 0.99) 100ms;
    }

    .img-frame {
      position: relative;
      overflow: hidden;
      line-height: 0;
    }

    @media screen and (max-width: $breakpoint-header) {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    .overlay {
      background: url("../images/angled_mask_with_corner_90.png") no-repeat;
      background-position: -333%;
      height: 100%;
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;

      width: 100%;

      .arrow {
        background: url("../images/rollover_arrow.png") no-repeat center;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        right: 0;
        opacity: 0;
        transform: scale(0.8);
        -webkit-transition: all ease 200ms;
        transition: all ease 200ms;
      }

      @media screen and (max-width: $breakpoint-header) {
        display: none;
      }
    }

    h4 {
      color: #e03314;
      font-size: 17px;
      font-family: "BreuerTextRegular", helvetica, arial;
      text-transform: uppercase;
      font-weight: normal;
      margin: 18px 0 6px 0;
      padding-right: 20px;
      line-height: 1.2em;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 20px;
      }
    }

    span.description {
      display: block;
      padding-right: 30px;
      font-size: 14px;
      line-height: 1.35em;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 16px;
      }
    }

    &:hover {
      text-decoration: none;
      -webkit-transition: all linear 200ms;
      transition: all linear 200ms;
      z-index: 5;

      h4 {
        text-decoration: underline;
      }
      .img-frame {
        overflow: hidden;
        line-height: 0;
      }

      img:first-child {
        transform: scale(1.4);
        transition: all linear 10s;
        transition-delay: 200ms;

        @media screen and (max-width: $breakpoint-header) {
          transform: scale(1);
        }
      }

      .overlay {
        -webkit-transition: all ease-in-out 350ms;
        transition: all ease-in-out 350ms;
        background-position: 100%;
        width: 101%;

        .arrow {
          opacity: 1;

          transform: scale(1);
          -webkit-transition: all ease 200ms;
          transition: all ease 200ms;
          transition-delay: 100ms;
        }
      }
    }
  }
}

.product {
  width: 33%;
  float: left;

  a {
    background: #f5f5f5;
    display: block;
    height: 200px;
    margin-top: 40px;
    padding: 40px;
    text-align: center;
    width: 100%;
  }
}

.product-module {
  display: none;
}

.data-table {
  table,
  .table-container {
    margin-bottom: 40px;
    width: 100%;
    display: table;
  }

  .row,
  .header,
  tr {
    background: url("../images/product-border.png") bottom repeat-x;
    font-size: 14px;
    background-position: bottom 1px right 0px;
    padding: 15px 15px 9px 15px;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color linear 300ms;
    display: table-row;

    span {
      display: table-cell;

      &.static-product-card-description {
        p {
          margin-top: 0;
        }
      }
    }

    .expand {
      float: left;
      margin-top: -5px;
      min-height: 6px;
      width: 5%;

      a {
        background: url("../images/icon_plus.png") center no-repeat;
        display: block;
        width: 30px;
        height: 30px;
        transition: all linear 200ms;
      }
    }

    &.open {
      background-color: rgba(0, 0, 0, 0.05);
      transition: background-color linear 300ms;

      .details {
        opacity: 1;
        transition: opacity linear 300ms;
      }

      .expand a {
        transform: rotate(45deg);
        transition: all linear 100ms;
      }
    }

    .product-name {
      float: left;
      padding-right: 20px;
      width: 29%;

      @media screen and (max-width: $breakpoint-small) {
        width: 50%;

        .m-title {
          display: none;
        }
      }
    }

    .product-group {
      float: left;
      width: 38%;

      @media screen and (max-width: $breakpoint-small) {
        width: 50%;

        .m-title {
          display: none;
        }
      }
    }

    .download-header {
      padding-right: 15px;
      text-align: center;

      @media screen and (max-width: $breakpoint-index-table) {
        display: none;
      }
    }

    .data-sheet-download {
      text-align: center;

      @media screen and (max-width: $breakpoint-index-table) {
        display: none;
      }

      a {
        background-image: url("../images/icon_download_orange.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }

    .msds-download {
      text-align: center;

      @media screen and (max-width: $breakpoint-index-table) {
        display: none;
      }

      a {
        background-image: url("../images/icon_download_orange.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }

    .contact {
      text-align: center;

      a {
        background: url("../images/icon_contact@2x_24.png") center no-repeat;
        background-size: 24px 24px;
        display: inline-block;
        width: 24px;
        height: 24px;
      }

      @media screen and (max-width: $breakpoint-index-table) {
        display: none;
      }
    }

    &.header,
    th,
    .header-column {
      border: 0;
      background: #79d3e5;
      color: #000000;
      font-family: "BreuerTextRegular", helvetica, arial;
      font-size: 16px;
      font-weight: normal;
      text-align: left;
      text-transform: uppercase;
      padding: 14px 15px 11px 15px;
      vertical-align: middle;
    }

    td,
    .header-row span,
    .row span {
      padding: 13px 15px 11px 15px;
      //vertical-align: bottom;
    }

    td {
      @include respond-max(480px) {
        padding: 13px 5px 11px 5px;
      }

      @include respond-max(380px) {
        width: 50%;
      }
    }

    &.product-group-header {
      background: none;
      font-family: "BreuerTextRegular", helvetica, arial;
      text-transform: uppercase;
      padding: 30px 15px 5px 15px;
      font-size: 15px;
    }

    &.product-group-header.v2 {
      background: rgba(0, 0, 0, 0.05);
      font-family: "BreuerTextRegular", helvetica, arial;
      text-transform: uppercase;
      padding: 13px 15px 11px 15px;
      font-size: 15px;
    }
  }

  .mobile-row {
    display: none;

    .data-sheet-download {
      display: block;

      span {
        display: block;
        float: left;
        width: 50%;
      }
    }

    .contact {
      display: block;

      span {
        display: block;
        float: left;
        width: 50%;
      }
    }

    .msds-download {
      display: block;

      span {
        display: block;
        float: left;
        width: 50%;
      }
    }
  }

  .expand-mobile-row {
    background: url("../images/icon_plus.png") no-repeat;
    display: none;
    float: left;
    height: 14px;
    width: 14px;
    margin-bottom: 10px;
    margin-top: 3px;
    margin-right: 5px;

    &.expanded {
      background: url("../images/icon_minus.png") no-repeat;
    }

    @media screen and (max-width: $breakpoint-index-table) {
      display: block;
    }

    @include respond-max(480px) {
      height: 31px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.product-search-bar {
  padding: 0 30px;
  .wrapper {
    position: relative;

    .toggle {
      position: absolute;
      top: 5px;
      left: 25px;
    }
  }
}

#live-search {
  width: 100%;
  max-width: 275px;
  margin: 30px auto;

  fieldset {
    border: 1px solid #64cade;
    border-radius: 3px;
    margin: 0;
    padding: 0;
  }

  // #filter-count {
  // 	color:rgba(0,0,0,.4);
  // 	display: inline-block;
  // 	float: right;
  // 	font-size:15px;
  // 	margin-top: 3px;
  // }

  input {
    border: 0;
    font-size: 14px;
    padding: 15px 15px 12px 15px;
    width: 85%;

    &:focus {
      border: 0;
      outline-width: 0;
    }
  }

  img {
    position: relative;
    top: 6px;
  }
}

.no-results {
  @extend .col12;
  display: none;
  font-size: 24px;
  padding-top: 50px;
}

.product-search-bar.fixed {
  background: #ffffff;
  position: fixed;
  top: 55px;
  left: 0px;
  z-index: 50;
  width: 100%;

  @media screen and (max-width: $breakpoint-small) {
    position: static;
    top: 0;
  }
}

.landing-header {
  background-color: rgba(0, 0, 0, 0.1);
  background-position: top;
  //background-size: 100% !important;
  background-attachment: fixed;

  &.page-editor {
    background-attachment: local !important;
  }

  @media screen and (max-width: 1280px) {
    background-size: auto 440px !important;
  }

  @media screen and (max-width: $breakpoint-medium) {
    background-size: auto 285px !important;
  }
}

body.comp {
  min-width: 1024px;

  .landing-header {
    background-color: rgba(0, 0, 0, 0.1);
    background-position: top;
    background-size: 100% !important;
    background-attachment: fixed;

    @media screen and (max-width: 1280px) {
      background-size: auto 440px !important;
    }

    &.page-editor {
      background-attachment: local !important;
    }
  }

  .nav-secondary img,
  .main-content img {
    max-width: 200%;
  }

  .main-content {
    padding-top: 96px;
  }
}

.index-pipe {
  color: rgba(0, 0, 0, 0.3);
}

body.contact {
  background: #000000;

  a {
    display: block;
    background-repeat: no-repeat;
    width: 100%;
    height: 2000px;
    background-position: top center;
  }
}

.tab-nav.contact-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 17px 0 26px 0;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 20px 0 0;
      padding: 0;
      display: inline-block;
      list-style-type: none;
      background: none;

      @media screen and (max-width: 450px) {
        width: 50%;
        margin: 0;
        display: block;
        float: left;
        font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
      }

      a {
        font-size: 18px;
        text-transform: uppercase;
        color: #b2b4b3;
        border-bottom: 4px solid rgba(255, 255, 255, 0);
        padding: 0 0 13px 30px;
        display: block;
        position: relative;

        @media screen and (max-width: $breakpoint-small) {
          font-size: 15px;
        }

        &:before {
          content: "";
          width: 26px;
          height: 26px;
          display: inline-block;
          position: relative;
          top: 6px;
          margin-right: 5px;

          @media screen and (max-width: 450px) {
            display: none;
          }
        }

        &.sales-technical-service:before {
          background: url("../images/contact-envelope-gray.png") no-repeat;
          background-size: 24px 24px !important;
          content: "";
          display: inline-block;
          height: 24px;
          width: 24px;
          position: absolute;
          top: 4px;
          left: 0;
        }

        &.customer-service:before {
          background: url("../images/contact-bubble-gray.png") no-repeat;
          background-size: 24px 24px !important;
          content: "";
          display: inline-block;
          height: 24px;
          width: 24px;
          position: absolute;
          top: 5px;
          left: 0;
        }

        &.active {
          color: #e23e21;
          border-bottom: 4px solid #e23e21;

          &.sales-technical-service:before {
            background: url("../images/contact-envelope-red.png") no-repeat;
          }

          &.customer-service:before {
            background: url("../images/contact-bubble-red.png") no-repeat;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

#tabs.contact-tabs {
  .tab {
    padding: 0;
  }
}

.contact-details {
  max-width: 465px;
  background: url("../images/dottedline.png") bottom repeat-x;
  margin-bottom: 26px;
  padding-bottom: 21px;

  &:after {
    display: table;
    content: "";
    clear: both;
  }

  > span {
    float: left;
    margin: 10px 10px 0 10px;
    font-size: 35px;
    font-weight: 800;
    color: #d0d2d1;
  }

  .dropdown {
    width: 45%;
    float: left;
    padding: 14px;
    border: 1px solid #d0d2d1;
    border-radius: 3px;

    .label {
      display: block;
      text-transform: uppercase;
      color: #e23e21;
      font-size: 13px;
      font-family: "BreuerTextRegular", helvetica, arial;
      margin-bottom: 4px;
    }

    select {
      width: 100%;
    }
  }
}

#sales-technical-service.tab {
  .form-row {
    margin-bottom: 20px;
  }
  .button-row {
    border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0;
    padding-bottom: 30px;
  }

  .give-us-a-call {
    margin-top: 5px;

    .call-button {
      padding: 17px;
      text-transform: uppercase;
      font-weight: 600;
      color: #e23e21;
      border-top: 1px solid #d0d2d1;
      border-bottom: 1px solid #d0d2d1;
      border-left: 1px solid #d0d2d1;

      &:hover {
        cursor: pointer;
      }

      &:after {
        content: "";
        background: url("../images/give_us_a_call_arrow.png") center right
          no-repeat;
        display: inline-block;
        position: relative;
        top: 22px;
        left: 15px;
        width: 29px;
        height: 58px;
      }

      span:before {
        content: "";
        //background: url('../images/icon_call_sprite.png') center left no-repeat;
        background: url("../images/contact-call-us.png") no-repeat;
        background-position: bottom center;
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
        display: inline-block;
        position: relative;
        top: 6px;
        margin-right: 5px;
      }
    }

    .phone-number {
      margin-left: 25px;
      font-size: 29px;
      float: right;
      margin-right: 32px;
      margin-top: 28px;
      position: absolute;
    }
  }
}

#customer-service > p {
  font-size: 16px;
}

#customer-service.tab {
  max-width: 465px;
  margin: 0;
  padding-top: 2px;
  font-size: 0.9em;

  h2 {
    text-transform: uppercase;
    font-size: 20px;
  }

  h3 {
    text-transform: none;
    font-family: "BreuerTextLight", helvetica, arial;
    font-size: 14px;
    text-align: left;
    font-weight: 800;
    margin: 0 0 15px 0;
  }

  dl {
    dt {
      font-weight: 800;
      text-transform: uppercase;
      float: left;
      width: 75px;
    }
  }

  .contact-location-group {
    @extend %clearfix;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
    margin-bottom: 5px;
    padding-bottom: 5px;

    &:last-child {
      border-bottom: none;
    }

    .contact-location {
      float: left;
      width: 50%;
    }

    ul {
      list-style: none;
      padding-left: 5px;

      li {
        background: url("../../images/icon-bullet.png") no-repeat 0px 8px;
        background-size: 9px 9px;
        letter-spacing: 0.3px;
        line-height: 1.7;
        margin-bottom: 15px;
        padding-left: 20px;
      }
    }
  }
}

.citation {
  padding-top: 5px;
  font-style: italic;
}
.citation-quote {
  font-size: 100px;
  text-align: right;
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
  color: #000;
  opacity: 0.15;
  position: absolute;
  top: 48px;
  right: 17px;
}

.solution-landing .m-nav {
  display: none;
}
