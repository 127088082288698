﻿.header-report {
    .report-dropdown {
        a {
            &.download-report {
                float: right;
                background: rgba(0,0,0,0.2);
                border: 2px solid rgba(255,255,255,0.4);
                color: white;
                padding: 13px 15px 14px 43px;
                font-family: 'BreuerTextRegular', helvetica, arial;
                font-weight: normal;
                font-size: 17px;
                border-radius: 4px;
                letter-spacing: 0;
                line-height: 1;
                margin-top: 6px;
                text-transform: uppercase;
                text-decoration: none;

                img {
                    position: absolute;
                    left: 6px;
                    top: 6px;
                    width: 32px;
                }
            }
        }

        .dropdown-links {
            text-transform: none;

            a {
                &.download-report {
                    width: 100%;
                    color: black;
                    margin: 0;
                    text-transform: none;
                    border-radius: 2px;
                    background-color: #f0f0f0;
                    border: solid 2px rgba(0, 0, 0, 0.3);
                }

                &.flipbook {
                    background-color: #f0f0f0;
                    border-bottom: 0;
                    border-radius: 2px 2px 0 0;
                    margin-bottom: 0;
                    color: black !important;

                    &:hover {
                        background-color: #e4e4e4 !important;
                    }
                }

                &.report {
                    background-color: #f0f0f0;
                    border-top: 0;
                    border-radius: 0 0 2px 2px;
                    margin-top: 0;
                    color: black !important;

                    &:hover {
                        background-color: #e4e4e4 !important;
                    }
                }
            }
        }

        li {
            &.sustainability-report {
                a {
                    &.download-report {
                        .down-arrow {
                            float: right;
                            width: 10px;
                            margin-top: 14px;
                            position: absolute;
                            right: 0;
                            margin-left: 235px;
                        }
                    }
                }
            }
        }
    }

    &.category-page {
        .report-dropdown {
            top: 0;
        }
    }
}

.wrapper {
    .header-report {
        .report-dropdown {
            li {
                &.sustainability-report {
                    a {
                        &.download-report {
                            &:hover {
                                background: rgba(0,0,0,0.4) !important;
                                border: 2px solid rgba(255,255,255,0.5);
                                color: white;
                            }
                        }
                    }
                }

                a {
                    &.download-report {
                        &:hover {
                            background-color: #e4e4e4 !important;
                            color: black;
                        }
                    }
                }
            }
        }
    }
}

.report-dropdown {
    list-style-type: none;
    position: relative;
    top: -117px;
    right: 0;
    float: right;
    width: 316px;
    li {
        width: 100%;
        min-width: 276px;
        background: #f1f1f1;
        color: black;
        margin: 0;
        border-radius: 0;
        border: 0;

        &:first-of-type {
            a {
                width: 100%;
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }

    a {
        width: 100%;
        margin: 0;
    }
}
@media (min-width:700px) {

	.desktop-button {
		display:block;
	}
	.mobile-button{
		display:none;
	}
}

@media (max-width:700px) {

	.report-dropdown.category-page {
		display:none;
	}
	.desktop-button {
		display:none;
	}
	.mobile-button{
		display:block;
		position: absolute;
		bottom: 0;
		left: 12px;
	}
    .report-dropdown {
		top: 30px;
		float:left;
		padding: 0 50px 0 0;
    }
	.header-report .report-dropdown a.download-report {
		background-color: #e4e4e4;
		color: black;
	}
}