// // general.scss
// // Define universal plain tag styles here
// // NOTE: No classes or IDs!

// // color variables
// $base-link-color: $grey-700 !default;
// $base-hairline-color: $grey-600 !default;

//https://www.paulirish.com/2012/box-sizing-border-box-ftw/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// // body element

// body {
//   margin: 0;
// }

// // Annotate breakpoints inside body psuedo-selector
// // so they may be read by javascript
// // More details: https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript

body::before {
  content: "large";
  display: none;

  @media ($bp-large-max) {
    content: "medium";
  }

  @media ($bp-x-small-max) {
    content: "small";
  }
}

// // flexible media

// object,
// video {
//   display: block;
//   max-width: 100%;
// }

// // lists

// ol,
// ul {
//   padding: 0;
//   margin-top: 0;
//   margin-left: 60px;
//   margin-bottom: 30px;

//   li {
//     margin-bottom: 10px;
//   }
// }

// // nested lists

// ul ul,
// ol ul,
// ol ol,
// ul ol {
//   margin-top: 10px;
// }

// // definition lists

// dl {
//   padding: 0;
//   margin-top: 0;
//   margin-left: 60px;
//   margin-bottom: 30px;

//   dd,
//   dt {
//     margin-bottom: 10px;
//   }
// }

// // links

// a {
//   color: $base-link-color;
//   transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

//   &:hover {
//     color: rgba($base-link-color, 0.7);
//   }
// }

// // hr

// hr {
//   border-bottom: 1px solid $base-hairline-color;
//   border-left: none;
//   border-right: none;
//   border-top: none;
//   margin: -1px 0 0;
//   box-sizing: content-box;
// }

// figure {
//   margin: 0;
// }

// table {
//   border-collapse: collapse;
// }

// th,
// td {
//   border: 1px solid $base-border-color;
//   padding: 10px;
//   font-size: 16px;
// }

// th {
//   background-color: $grey-300;
// }


.u-visuallyhidden{
  @extend %visuallyhidden;
}
