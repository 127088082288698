$multi-column-space: 30px;

.multi-column {
  @include component-whitespace;
  color: $cabot-darkest-gray;

  &__title {
    @extend .underlined-header;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;

    .multi-column--two-columns & {
      margin-top: 56px;
    }
    @media (max-width: 1350px) {
      padding-left: $gutter;
      padding-right: $gutter;
    }
  }

  &__background-container {
    position: relative;
    width: 100%;

    @media ($bp-medium-max) {
      margin-top: 56px;
    }

    &:before {
      content: "";
      background-color: $cabot-lightest-gray;
      top: 180px;
      left: 0;
      height: calc(100% - 180px);
      position: absolute;
      width: 100%;

      @media ($bp-large-max) {
        top: 150px;
        height: calc(100% - 150px);
      }

      @media ($bp-medium-max) {
        height: 100%;
        top: 0;
      }

      .multi-column--two-columns & {
        height: 100%;
        top: 0;
      }
    }
  }

  &__column {
    margin: 56px auto $multi-column-space auto;
    order: 1; //default so we can override it with featured.
    position: relative;
    width: 100%;

    @media ($bp-medium-min) {
      width: calc(66.67% - #{$multi-column-space});
      margin-bottom: 70px;

      & + & {
        margin-left: $multi-column-space;
        width: calc(33.33% - #{$multi-column-space});
      }

      @include at-least(3) {
        width: calc(33.33% - #{$multi-column-space});
      }
    }
    @media ($bp-medium-max) {
      &--featured {
        order: 0;
      }
    }
  }

  &__type {
    font-family: $bold-font;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.21px;
    margin-bottom: 16px;
  }

  &__item {
    & + & {
      border-top: solid 1px $cabot-medium-gray;
      margin-top: 48px;
      padding-top: 40px;
    }

    & iframe {
      max-width: 100%;
    }
  }

  &__image {
    width: 100%;
  }

  &__image + &__content {
    margin-top: 25px;
  }

  &__column-title {
    font-size: 27px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }

  &__description {
    font-size: 16px;
    margin-top: 24px;
    text-align: left;
  }

  //legacy link styles very opinionated
  a.multi-column__title-link {
    color: $cabot-darkest-gray;
    font-family: $bold-font;
    font-size: 22px;
    font-weight: 500;
    display: block;
  }

  &__image + &__title-link {
    font-size: 27px;
    margin-top: 32px;
  }

  &__meta {
    color: $cabot-dark-gray;
    font-size: 16px;
    letter-spacing: 0.17px;
    display: flex;
    justify-content: space-between;
  }

  &__title-link + &__meta {
    margin-top: 24px;
  }

  &__view-more {
    display: block;
    margin-top: 40px;
  }
}
