// styles for the staging area

.index{
	height:100%;
	padding:100px;
	width:100%;
	background:#ffffff;

	@media screen and (max-width:$breakpoint-medium){
		padding:20px;
	}
}



.homepage {
	padding-top: 130px;

	@media screen and (max-width:$breakpoint-medium){
		padding-top:0;
	}


	
	header.header {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		
		@media screen and (max-width:$breakpoint-medium){
			position: relative;
		}
	}
}

.header-tile {
	background: url(../../images/pattern_tile3.png) top left repeat;
	background-attachment: fixed;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: .1;
}

.home-panel {
	height: 900px;
	background-position: center top; 
	background-size: cover;
	display: table;
	width: 100%;
	position: relative;
	backface-visibility: visible;

	&#panel-1:before,
	&#panel-2:before,
	&#panel-3:before,
	&#panel-4:before {
		background: url("../../images/pattern_tile3.png") top left repeat;
		background-attachment: fixed;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .1;
		content: "";
	}

	&#panel-1 {
		background-color: #f2f4f3;
		background-attachment: fixed;
		background-size: cover;

		@include respond-max(1025px) {
			background-size: auto 100%;
		}

		@media screen and (max-width: $breakpoint-small) {
			background-size: 300%;
			background-attachment: scroll;
		}



		.panel-content {
			width: 100%;
			padding-right: 235px;
			padding-top: 40px;



			p {
				font-size: 21px !important;
				padding-right: 150px;
				
				@media screen and (max-width: $breakpoint-header) {
					padding-right: 0px;
				}

				@media screen and (max-width: $breakpoint-medium) {
					padding-right: 0px;
					font-size: 18px !important;
				}

				@media screen and (max-width: $breakpoint-small) {
					font-size: 15px !important;
				}
			}

			h1 {
				font-size: 57px !important;
				letter-spacing: -1px;

				@media screen and (max-width: $breakpoint-medium) {
					font-size: 51px !important; 
				}

				@media screen and (max-width: $breakpoint-small) {
					font-size: 42px !important; 
				}
			}
		}

		.home-down-arrow {
			position: absolute;
			bottom: 20px;
			left: 50%;
			margin-left: -33px;

			@media screen and (max-width:$breakpoint-small){
				display: none;
			}	
		}
	}

	&#panel-2 {
		background-color: rgba(0, 0, 0, .1);
		background-attachment: fixed;

		@include respond-max(1025px) {
			background-size: auto 100%;
		}
		
		@media screen and (max-width: $breakpoint-small) {
			background: none !important;
			border-bottom: 1px solid rgba(0,0,0,.1);
		}
	}

	&#panel-3 {
		background-color: rgba(0, 0, 0, .2);
		background-attachment: fixed;

		@include respond-max(1025px) {
			background-size: auto 100%;
		}
		
		@media screen and (max-width: $breakpoint-small) {
			background: none !important;
			border-bottom: 1px solid rgba(0,0,0,.1);
		}
	}

	&#panel-4 {
		background-color: rgba(0, 0, 0, 0);
		background-attachment: fixed;

		@include respond-max(1025px) {
			background-size: auto 100%;
		}
		
		@media screen and (max-width: $breakpoint-small) {
			background: none !important;
			border-bottom: 1px solid rgba(0,0,0,.1);
		}
	}

	&#panel-5 {
		background-color: rgba(0, 0, 0, 0);
		background-attachment: fixed;
		padding: 96px 0;

		@include respond-max(1025px) {
			background-size: auto 100%;
		}
		
		@media screen and (max-width: $breakpoint-small) {
			padding: 25px 0 50px 0;
			background: none !important;
			border-bottom: 1px solid rgba(0,0,0,.1);
			background-repeat: repeat-y
		}

		
			.wrapper {

				@media screen and (max-width: 1100px) {
					padding-right: 65px;
				}
				@media screen and (max-width: $breakpoint-medium) {
					padding-right: 25px;
				}
			}
	}
	.v-align {
    	display: table-cell;
    	vertical-align: middle;
	}

	.panel-content, 
	.latest {
		width: 50%;
		padding-bottom: 30px;
		font-size: 19px;

		@media screen and (max-width: $breakpoint-medium) {
			width: 100%;
			padding-right: 0 !important;
			font-size: 18px;
		}

		@media screen and (max-width: $breakpoint-small) {
			padding: 40px 0 60px 0;
			font-size: 15px;
		}

		h1 {
			padding: 0;
			margin: 0;
			font-size: 44px;
			line-height: 1.1em;
			font-family: "BreuerTextMedium", helvetica, Arial, sans-serif;

			@media screen and (max-width: $breakpoint-medium) {
				font-size: 38px;
			}

			&:before {
				display: none;
			}

			a {
				color: rgba(255, 255, 255, 1) ;

				&:hover {
					color: rgba(255, 255, 255, .8);
					text-decoration: none;
				}
			}
		}

		p {
			margin: 39px 0 4px 0;
			line-height: 1.6em;
			letter-spacing: 0;

			&.latest {
				margin-top: 130px;
			}
		}

		a.panel-cta {
			padding-top: 33px;
			color: #e23e21;
			display: table;
			position: relative;
			border-bottom: 1px solid rgba(247, 164, 47, .3);
			margin-left: 27px;

			@media screen and (max-width: $breakpoint-small) {
				font-family: "BreuerTextRegular"; 
			}

			&:hover {
				text-decoration: none;
				border-bottom: 1px solid rgba(247, 164, 47, 1);
			}


			img {
				position: absolute;
				left: -24px;
				bottom: 7px;

			}
		}


	}
}

// touch device fix
.mod-touch .home-panel#panel-2,
.mod-touch .home-panel#panel-3,
.mod-touch .home-panel#panel-4 {
	background-attachment: scroll;
}


.homepage-nav {
	position: fixed;
	right: 25px;
	top: 50%;
	list-style-type: none;
	margin-top: -148px;
	z-index: 5;

	@media screen and (max-width: $breakpoint-medium) {
		display: none;
	}

	* {
		transition: all linear 200ms;
	}
	
	li {
		position: relative;

		.label {
			opacity: 1;
			position: absolute;
			right: 120px;
			top: 33px;
			font-size: 14px;
			color: #000;
			width: 115px;
			text-align: right;
			line-height: 1.3em;
			transition: all linear 200ms 200ms;
			letter-spacing: .5px;
		}
	}

	&.scrolled {

		a {
			width: 10px;
			background: rgba(255, 255, 255, 1);

			img {
				opacity: 0;
			}
		}
		
		.label {
			opacity: 0;
			transition: all linear 20ms 0ms ;
		}
	}

	a,
	&.scrolled:hover a {
		color: #ffffff;
		display: block;
		height: 100px;
		width: 100px;
		margin-bottom: 1px;
		position: relative;
		overflow: hidden;
		
		&:hover {

			background: #64cade;

			img {
				opacity: .15;
			}
		}

		&.active {
			height: 10px;

			img {
				opacity: 0;
			}
		}

		img {
			opacity: 1;
			min-width: 100px;
		}
	}

	&.hidden {
		visibility: hidden;
	}

	a.active {
		height: 10px;
		background: #64cade;

		img {
			opacity: 0;
		}
	}
}


.hp-feature {
	background: rgba(255, 255, 255, .7);
	margin-bottom: 36px;
	display: block;
	color: #000000;
	position: relative;
	overflow: hidden;
	transition: linear 200ms all;

	@media screen and (max-width: $breakpoint-medium) {
		height: none;
		margin-bottom: 25px;
	}



	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0%;
		transition: linear 100ms all;
		border: 1px solid rgba(0, 0, 0, .09);
	}

	&:hover {
		color: #ffffff;
		transition: linear 100ms all;

		span.feature-content {
			color: white !important;
		}

		h3 {
			color: #ffffff;
			transition: linear 100ms all;
		}

		.arrow path {
			fill: #ffffff;
			transition: linear 100ms all;
		}

		&:before {
			left: 0%;
			background: rgba( 218, 26, 0, .86);
			transition: linear 100ms all;
		}

		span.up, span.down {
			color: #ffffff;
		}
	}

	.cta-graphic {
		background: center no-repeat;
		display: block;
		float: left;
		width: 33%;
		height: 170px;

		@media screen and (max-width: $breakpoint-medium) {
			display: none;
		}

	}

	span {
		&.feature-content {
			float: left;
			width: 600px;
			padding: 29px 65px 0 40px;
			display: inline-block;
			width: 66%;
			z-index: 10;
			position: relative;
			color: black !important;

			@media screen and (max-width: $breakpoint-medium) {
				padding: 29px 65px 29px 40px;
				width: 100%;
			}

			@media screen and (max-width: $breakpoint-small) {
				padding: 25px 65px 25px 25px;
				width: 100%;
			}

		}

		&.up {
			color: #54bb4b;
		}

		&.down {
			display: inline-block;
			color: rgba( 218, 26, 0, .86);
			-webkit-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}

	h3 {
		padding: 0 0 6px 0;
		margin: 0;
		text-align: left;
		font-family: "BreuerTextRegular", helvetica, Arial, sans-serif;
		line-height: 1.2em; 
	}

	&:after {
		display: table;
		content: "";
		clear: both;
	}

	.arrow {
		position: absolute;
		top: 50%;
		right: 20px;
		margin-top: -14px;
		width: 32px;
		height: 32px;
	}
}

a.hp-feature:last-of-type {
	margin-bottom: 0;
}







.bounce {
	-webkit-animation: bounce 1.2s ease-in-out;
	animation: bounce 1.2s ease-in-out;
	position: relative;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

 
@keyframes bounce
{ 0% {
	top: 0;
} 50% {
	top: 6px;
} 100% {
	top: 0;
}}

@-webkit-keyframes bounce
{ 0% {
	top: 0;
} 50% {
	top: 6px;
} 100% {
	top: 0;
}}



.fadein-1 {
	animation: fadein 1.2s ease-in-out;
	position: relative;
}


.fadein-2 {
	animation: fadein 1.2s ease-in-out 100ms;
	position: relative;
}

.fadein-3 {
	animation: fadein 1.2s ease-in-out 200ms;
	position: relative;
}
 






@keyframes fadein
{ 0% {
	opacity: 0;
} 100% {
	opacity: 1;
}}


/************************************** Homepage language tweaks */

// Japan
.ja-jp .homepage-nav li.panel-3 .label {
	top: 16px;
	width: 150px;
}

// Brazil
.pt-br .homepage-nav li.panel-3 .label,
.pt-br .homepage-nav li.panel-4 .label {
	top: 38px;
}
.pt-br .homepage-nav li.panel-5 .label {
	top: 26px;
}

// Germany
.de-de .homepage-nav li.panel-2 .label,
.de-de .homepage-nav li.panel-3 .label {
	top: 38px;
}
.de-de .homepage-nav li.panel-4 .label,
.de-de .homepage-nav li.panel-5 .label {
	top: 28px;
}

// Netherlands
.nl-nl .homepage-nav li.panel-5 .label {
	top: 26px;
}

// Argintina 
.es-ar .homepage-nav li.panel-2 .label {
	top: 26px;
}
.es-ar .homepage-nav li.panel-5 .label {
	top: 16px;
}