
$break-mid: 500px;

@mixin respond-to($media) {
	@if $media == small {
		@media only screen and (max-width: $break-mid) { @content; }
	}
	@else if $media == large {
		@media only screen and (min-width: $break-mid + 1) { @content; }
	}
}


$fix-mqs: false !default;

@mixin respond-max($width) {
	@if $fix-mqs {
		@if $fix-mqs < $width {
			@content;
		}
	}

	@else {
		@media screen and (max-width: $width) {
			@content;
		}
	}
}

@mixin respond-min($width) {
	@if $fix-mqs {
		@if $fix-mqs > $width {
			@content;
		}
	}

	@else {
		@media screen and (min-width: $width) {
			
		}
	}
}



/* grid mixins */

@mixin col {
	border:0px solid rgba(0,0,0,0);
	float:left;
	@include box-sizing(border-box);
	@include background-clip(padding-box !important);
}





