﻿$gutter-width:40px;



.grid-layout{
	// 	-moz-box-sizing: content-box;
	// 	-webkit-box-sizing:	content-box; 
  // box-sizing: content-box;
  // hiding for legacy code white space
	overflow-x:hidden;
	margin-left:  -$gutter-width / 2;
	margin-right:  -$gutter-width / 2;
	
}

.grid-layout:after{
	display:table;
	content: "";
	clear:both;
}

.grid-layout,
.main-content.one-col {
	.col1{
		width:1 / 12 * 100%;
	}

	.col2{
		width: 2 / 12 * 100%;
	}

	.col3{
		width: 3 / 12 * 100%;
	}

	.col4{
		width: 4 / 12 * 100%;
	}

	.col5{
		width: 5 / 12 * 100%;
	}

	.col6{
		width: 6 / 12 * 100%;
	}

	.col7{
		width: 7 / 12 * 100%;
	}

	.col8{
		width: 8 / 12 * 100%;
	}

	.col9{
		width: 9 / 12 * 100%;
	}

	.col10{
		width: 10 / 12 * 100%;
	}

	.col11{
		width: 11 / 12 * 100%;
	}

	.col12{
		width: 12 / 12 * 100%;
	}
}

.main-content{ /* uses a 9 col grid for the content box*/

	.col1{
		width:1 / 9 * 100%;
	}

	.col2{
		width: 2 / 9 * 100%;
	}

	.col3{
		width: 3 / 9 * 100%;
	}

	.col4{
		width: 4 / 9 * 100%;
	}

	.col5{
		width: 5 / 9 * 100%;
	}

	.col6{
		width: 6 / 9 * 100%;
	}

	.col7{
		width: 7 / 9 * 100%;
	}

	.col8{
		width: 8 / 9 * 100%;
	}

	.col9{
		width: 9 / 9 * 100%;
	}

	.col10{
		width: 10 / 9 * 100%;
	}

	.col11{
		width: 11 / 9 * 100%;
	}

	.col12{
		width: 12 / 9 * 100%;
	}

}


.col1, 
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12{

	float:left;
	padding-left: $gutter-width / 2;
	padding-right: $gutter-width / 2;

	@media screen and (max-width:$breakpoint-medium){
		width:100% !important;
	}
}









